<template>
    <div>
        <div class="row m-3">
            <div class="col-9">
                <div class="row">
                    <h3>Due diligence Network</h3>
                    <svg ref="svg" style="background-color:white"></svg>
                </div>
            </div>
            <div class="col-3 right-column">
                <h3>Graph options</h3>
                <choose-force-graph-options
                    :forceGraphOptions="forceGraphOptions"
                    @newForceGraphOption="changeForceGraphOption"
                >
                </choose-force-graph-options>

                <div v-if="showPlayer">
                    <h3>Player's portfolio</h3>
                    <h4>{{ this.clicked_player.name }}</h4>
                    <table class="clickedPlayer">
                        <tr>
                            <td><b>Publication</b></td>
                            <td><b>{{ clicked_player.name === 'Other' ? 'Applicant' : 'Node' }}</b></td>
                        </tr>
                        <tr v-for="patent in this.clicked_player.list_patents" :key="get_patent_key(patent)">
                            <td 
                                @click="clickOnPatent(patent)"
                                class="link"
                                >{{ get_patent_name(patent) }}</td>
                            <td v-html="clicked_player.name === 'Other' ? (patent.NPA ? patent.NPA: patent.player) : patent.whereAmI"></td>
                        </tr>
                    </table>
                </div>
                <div ref="scrollTarget">
                    <div v-if="showPatent">
                        <h3>Card data</h3>
                        <show-patent 
                            :patent="clicked_patent"
                            :viewMode="'Sankey'"
                            :index="clicked_patent.patent_index"
                            @newComment="updateComment"
                            @newCriticity="updateCriticity"
                            @newIncludeInTimeline="updateIncludeInTimeline"
                        >
                        </show-patent>
                    </div>
                </div>
            </div>
        </div>
        <div class="tooltip"></div> 
    </div>
</template>
  
<script>
    import * as d3 from 'd3';
    // import {Swatches} from "@d3/color-legend"

    // import { sankey, sankeyLinkHorizontal } from 'd3-sankey';
    import ChooseForceGraphOptions from '@/components/ChooseForceGraphOptions.vue'
    import ShowPatent from '@/components/ShowPatent.vue'
    import { 
        // getTotalApplicants,
        getApplicantsFromPatent,
        transform_node_to_force_data,
        changePatentPropertyInNode,
    } from '@/functions/treeutils.js';
    
    function linkArc(d) {
        let r = Math.hypot(d.target.x - d.source.x, d.target.y - d.source.y);
        
        return `
            M${d.source.x},${d.source.y}
            A${r},${r} 0 0,1 ${d.target.x},${d.target.y}
        `;
    }
    
    function drag(simulation) {
  
        function dragstarted(event, d) {
            if (!event.active) simulation.alphaTarget(0.3).restart();
            d.fx = d.x;
            d.fy = d.y;
        }
        
        function dragged(event, d) {
            d.fx = event.x;
            d.fy = event.y;
        }
        
        function dragended(event, d) {
            if (!event.active) simulation.alphaTarget(0);
            d.fx = null;
            d.fy = null;
        }
        
        return d3.drag()
            .on("start", dragstarted)
            .on("drag", dragged)
            .on("end", dragended);
    }

    export default { 
        props: ['tree'],
        data() {
            return {
                showPlayer: false,
                showPatent: false,
                clicked_player: {},
                clicked_patent: {},
                clicked_index: 0,
                forceGraphOptions: {"arrowLength":"75","textSize":"15"},
                npl_types: {
                    "merge_and_acquisition": "Merge & Acquisition",
                    "scientific_article": "Scientific article",
                    "investment": "Investissement news",
                    "partnership_and_collaboration": "Partnership & Collaboration",
                    "hiring": "Hiring",
                    "other": "Other"
                },

            }
        },
        name: 'force-chart',
        components: { ChooseForceGraphOptions, ShowPatent },
        computed: {
            items() {
                return transform_node_to_force_data(this.tree, this.selected_applicants)
            },
            // total_applicants() {
            //     return getTotalApplicants(this.tree).sort((a, b) => b.value-a.value) || []
            // },
            text_size() {
                return this.forceGraphOptions["textSize"]
            },
            arrow_length() {
                return this.forceGraphOptions["arrowLength"]
            }
        },
        mounted() {
            this.forceGraphOptions = {"arrowLength":"75","textSize":"15"}
            this.reRenderForceGraph()
        },
        watch: {
            tree() {
                this.forceGraphOptions = {"arrowLength":"75","textSize":"15"}
                this.reRenderForceGraph();
            },
        },
        methods: {
            get_patent_key(patent){
                if ("ID" in patent) {
                    return patent.ID
                } else if ("title" in patent) {
                    return patent.title
                } else {
                    return patent.order
                }
            },
            get_patent_name(patent){
                if("ID" in patent) {
                    return patent.ID.split("_")[0]
                } else {
                    return patent.title
                }
                
            },
            updateComment(index, newComment){
                let whereAmI = this.clicked_patent.whereAmI
                let newTree = changePatentPropertyInNode(this.tree, whereAmI, index, 'patentComment', newComment)
                this.$emit('update-tree', newTree)
            },
            updateCriticity(index, newCriticity){
                let whereAmI = this.clicked_patent.whereAmI
                let newTree = changePatentPropertyInNode(this.tree, whereAmI, index, 'patentCriticity', newCriticity)
                this.$emit('update-tree', newTree)
            },
            updateIncludeInTimeline(index, newIncludeInTimeline){
                let whereAmI = this.clicked_patent.whereAmI
                let newTree = changePatentPropertyInNode(this.tree, whereAmI, index, 'includeInTimeline', newIncludeInTimeline)
                this.$emit('update-tree', newTree)
            }, 
            scrollToTarget() {
                const el = this.$refs.scrollTarget;

                if (el) {
                    // Use el.scrollIntoView() to instantly scroll to the element
                    el.scrollIntoView({behavior: 'smooth'});
                }
            },
            clickOnPatent(patent){
                this.showPatent=true;
                this.clicked_patent=patent;
                this.scrollToTarget()
            },
            getPatentListingFromApplicant(node, applicant_name){
                let list_patents = []
                if (node.patents) {
                    let modified_patent_listing = node.patents.map((element, index) => {
                        element['patent_index'] = index
                        return element
                    })
                    list_patents = modified_patent_listing.filter((element) => {
                        let applicants = getApplicantsFromPatent(element)
                        if (applicants.includes(applicant_name)){
                            return true
                        }
                        return false
                    })
                }

                if (node.nodes){
                    for (let i=0; i < node.nodes.length; i ++){
                        list_patents = [...list_patents, ...this.getPatentListingFromApplicant(node.nodes[i], applicant_name)]
                    }
                }
                return list_patents
            },
            changeForceGraphOption(value){
                this.forceGraphOptions = value;
                this.reRenderForceGraph();
            },
            reRenderForceGraph() {
                const svg = d3.select("svg");
                svg.selectAll("*").remove();
                this.showPlayer=false;
                this.showPatent=false;

                this.renderForceGraph()
            },
            buildListPatentsFromApplicants(name){
                let list_patents = []
                list_patents = this.getPatentListingFromApplicant(this.tree, name)
                this.clicked_player = {"name": name, "list_patents": list_patents}
            },
            renderForceGraph() {
                const width = 800;
                const height = 600;
                const types = Array.from(new Set(this.items.map(d => d.type)));
                let nodes = Array.from(new Set(this.items.flatMap(l => [l.source, l.target])), id => ({id, value: 2}));
                
                const links = this.items.map(d => {let e = Object.create(d);e.type=d.type; return e;})
                const color = d3.scaleOrdinal(types, d3.schemeCategory10);

                /* eslint-disable-next-line */
                const mouseover = (event, d) => {
                    console.log(d)
                    // tooltip.style("opacity", 1);
                };
                /* eslint-disable-next-line */
                const mouseclick = (event, d) => {
                    this.buildListPatentsFromApplicants(d.id)
                    this.showPlayer = true;
                    this.showPatent = false
                };


                const simulation = d3.forceSimulation(nodes)
                    /* eslint-disable-next-line */
                    .force("link", d3.forceLink(links).id(d => d.id).distance(this.arrow_length))
                    .force("charge", d3.forceManyBody().strength(-300))
                    .force("x", d3.forceX())
                    .force("y", d3.forceY());

                const svg = d3.select("svg")
                    .attr("viewBox", [-width / 2, -height / 2, width, height])
                    .attr("width", width)
                    .attr("height", height)
                    .attr("style", "max-width: 100%; height: auto; background-color: white; font: " + this.text_size + "px sans-serif;")

                
                // Per-type markers, as they don't inherit styles.
                svg.append("defs").selectAll("marker")
                    .data(types)
                    .join("marker")
                    .attr("id", d => `arrow-${d}`)
                    .attr("viewBox", "0 -5 10 10")
                    .attr("refX", 15)
                    .attr("refY", -0.5)
                    .attr("markerWidth", d => {console.log(d); return 10})
                    .attr("markerHeight", 10)
                    .attr("orient", "auto")
                    .append("path")
                    .attr("fill", color)
                    .attr("d", "M0,-5L10,0L0,5");

                const link = svg.append("g")
                    .attr("fill", "none")
                    .attr("stroke-width", 2)
                    .selectAll("path")
                    .data(links)
                    .join("path")
                    .attr("stroke", d => color(d.type))
                    .attr("marker-end", (d) => {return (['investment', 'hiring', 'merge_and_acquisition'].includes(d.type)) ? `url(${new URL(`#arrow-${d.type}`, location)})` : ""}); // partnership_and_collaboration, 'coownership', 'scientific_article', 'other'

                const node = svg.append("g")
                    .attr("fill", "currentColor")
                    .attr("stroke-linecap", "round")
                    .attr("stroke-linejoin", "round")
                    .selectAll("g")
                    .data(nodes)
                    .join("g")
                    .call(drag(simulation))
                    .on("click", mouseclick)
                    // .on("mousemove", mousemove)
                    // .on("mouseleave", mouseleave)
                    .on("mouseover", mouseover);

                node.append("circle")
                    .style("fill", "white")
                    .attr("stroke-width", 1)
                    .attr("stroke", "black")
                    .attr("r", (d) => {return 4*d.value});

                node.append("text")
                    .attr("x", 12)
                    .attr("y", "0.31em")
                    .text((d) => {
                        let name = d.id;
                        if (name.length > 0) {
                            name = name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
                        }
                        if (name.length > 20){
                            name = name.slice(0,20) + '...'
                        }

                        return name
                    })
                    .clone(true).lower()
                    .attr("fill", "none")
                    .attr("stroke", "white")
                    .attr("stroke-width", 3)

                simulation.on("tick", () => {
                    link.attr("d", linkArc);
                    node.attr("transform", d => `translate(${d.x},${d.y})`);
                });

                // invalidation.then(() => simulation.stop());

                return Object.assign(svg.node(), {scales: {color}});
                // return svg.node();
            }
        }
    }
</script>
  
<style scoped>

h2 {
    margin-top: 30px;
    margin-bottom : 30px
}
h3 {
    margin-top: 20px;
}

svg {
    padding: 10px;
    border: solid lightblue;
    margin-bottom: 30px;
    /* padding-inline:  max(2rem, calc(50% - 24rem)); */
}

.link {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
}
.radioselect {
    padding: 10px;
    margin-left: 30px;
    margin: 5px;
    margin-bottom: 10px;
    text-align: left;
}

table tr td {
    border: solid black 1px;
    text-align: center;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
}

#editApplicantListing {
    margin-left: 10px;
}

.right-column {
    border: 1px solid lightgrey;
    max-height: 80vh;          /* not available in Bootstrap */
    overflow-y: scroll;
}

</style>