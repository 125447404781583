<template>
    <template v-if="this.viewMode == 'Edit'">
        <span>
            <span v-popover tabindex="0" :title="popover_title"
                :data-bs-content="popover_content_part_1 + '\n' + (patent.patentComment ? patent.patentComment + '\n' : '') + popover_content_part_2"
                class="mx-1 do_underline">
                {{ get_patent_number }}&#32;
            </span>
            <span v-if="showDelete">
                <i class="ri-delete-bin-2-line ms-1 me-3 do_underline" @click="toggleDelete"></i>&#32;
                <button v-if="confirmDelete" class="btn btn-light " type="button" @click="deletePatent">Delete patent/npl {{
                    get_patent_number }}? This action cannot be reversed.</button>
            </span>
        </span>
    </template>
    <template v-else-if="['View'].includes(this.viewMode)">
        <div class="card m-1">
            <div class="card-header row" style="--bs-gutter-x: 0">
                <div :style="{ 'display': 'flex', justifyContent: 'space-evenly' }">
                    <div @click="newPatentOrder(-1)" :style="{ padding: 6, lineHeight: '40px' }">
                        <i class="ri-arrow-left-s-line" v-tooltip title="Move left"></i>
                    </div>
                    <div :style="{ 'display': 'flex', justifyContent: 'center' }">
                        <div class="form-check-inline">
                            <input type="radio" class="btn btn-check btn-sm rounded-pill" v-model="myCriticity" value="1"
                                :id="get_patent_number + 'btn-radio-1'">
                            <label class="btn btn-outline-success" :for="get_patent_number + 'btn-radio-1'">1</label>
                        </div>
                        <div class="form-check-inline">
                            <input type="radio" class="btn-check btn-sm rounded-pill" v-model="myCriticity" value="2"
                                :id="get_patent_number + 'btn-radio-2'">
                            <label class="btn btn-outline-warning" :for="get_patent_number + 'btn-radio-2'">2</label>
                        </div>
                        <div class="form-check-inline">
                            <input type="radio" class="btn-check btn-sm rounded-pill" v-model="myCriticity" value="3"
                                :id="get_patent_number + 'btn-radio-3'">
                            <label class="btn btn-outline-danger" :for="get_patent_number + 'btn-radio-3'">3</label>
                        </div>
                    </div>
                    <div @click="newPatentOrder(1)" :style="{ padding: 6, lineHeight: '40px' }">
                        <i class="ri-arrow-right-s-line" v-tooltip title="Move right"></i>
                    </div>
                </div>
            </div>

            <div class="card-body">
                <span class="patent_number">{{ get_patent_number }}</span>
                <br />
                <br />
                <div v-if="editNplContent">
                    Type:
                    <select class="form-select" v-model="newNplType" aria-label="Default select example">
                        <option disabled value="-1">Choose Type of NPL</option> / / / /
                        <option v-for="(value, key) in types" :value="key" :key="key">{{ value }}</option>
                    </select>
                    Player: <input class="form-control" v-model="newNplPlayer">
                    URL: <input class="form-control" v-model="newNplUrl">
                    Input date: <input class="form-control" v-model="newNplInputDate">

                    <button class="btn btn-light" @click="validateNewNplContent">
                        <i class="ri-check-fill"></i>
                    </button>
                </div>
                <div v-else>
                    <div v-html="this.popover_content_part_1"></div>
                    <button v-if="is_an_npl" class="btn btn-light" @click="editNplContent = true">
                        <i class="ri-edit-fill"></i>
                    </button>
                </div>
            </div>
            <div v-if="editComment" class="card-body">
                <textarea placeholder="Enter a comment" class="form-control" v-model="newPatentComment"></textarea>
                <button class="btn btn-light" @click="validateComment">
                    <i class="ri-check-fill"></i>
                </button>
            </div>
            <div v-else class="card-body comment-box">
                <span :class="patent.patentComment ? 'strong' : ''">{{ patent.patentComment ? patent.patentComment :
                    'Comment: ...empty' }}</span>

                <button class="btn btn-light mx-2" @click="editComment = true">
                    <i class="ri-edit-fill"></i>
                </button>
            </div>
            <div class="form-group">
                <input class="form-check-input mx-3" type="checkbox" id="timelineCheck" v-model="newIncludeInTimeline">
                <label class="form-check-label" for="timelineCheck">Include in timeline</label>
            </div>
            <div v-if="popover_content_part_2" class="card-body">
                <div>
                    <div class="small" v-html="this.popover_content_part_2"></div>
                </div>
            </div>

            <div class="card-body row" v-if="has_pictures">
                <div class="col-1" role="button" @click="setActive(active - 1)" style="font-weight: bolder;">
                    <i class="ri-arrow-left-s-line" v-tooltip title="See previous image"></i>
                </div>
                <div :id="carousel_id" class="carousel slide col-9" data-bs-ride="carousel">
                    <div class="carousel-inner">
                        <div v-for="(picture, index) in pictures" :key="'picture-' + index"
                            :class="{ 'carousel-item': true, 'active': index === active }">
                            <img :src="picture" class="d-block w-100" alt="Patent image">
                        </div>
                    </div>
                </div>
                <div class="col-1" role="button" @click="setActive(active + 1)" style="font-weight: bolder;">
                    <i class="ri-arrow-right-s-line" v-tooltip title="See next image"></i>
                </div>

            </div>
            <div v-if="has_also" class="card-footer" v-html="this.also_present_in_string"></div>

        </div>

    </template>
    <template v-else-if="['Play', 'Sankey'].includes(this.viewMode)">
        <span class="patent_number">{{ get_patent_number }}</span>
        <div class="card m-1">
            <div class="card-header" style="--bs-gutter-x: 0">
                <div class="align-middle center">
                    {{ popover_title }}
                </div>
            </div>
            <div class="card-body">
                <div class="my-2">
                    <span>Criticity: </span>
                    <div class="form-check-inline">
                        <input type="radio" class="btn btn-check btn-sm rounded-pill" v-model="myCriticity" value="1"
                            :id="get_patent_number + 'btn-radio-1'">
                        <label class="btn btn-outline-success" :for="get_patent_number + 'btn-radio-1'">1</label>
                    </div>
                    <div class="form-check-inline">
                        <input type="radio" class="btn-check btn-sm rounded-pill" v-model="myCriticity" value="2"
                            :id="get_patent_number + 'btn-radio-2'">
                        <label class="btn btn-outline-warning" :for="get_patent_number + 'btn-radio-2'">2</label>
                    </div>
                    <div class="form-check-inline">
                        <input type="radio" class="btn-check btn-sm rounded-pill" v-model="myCriticity" value="3"
                            :id="get_patent_number + 'btn-radio-3'">
                        <label class="btn btn-outline-danger" :for="get_patent_number + 'btn-radio-3'">3</label>
                    </div>
                </div>

                <div v-html="this.popover_content_part_1"></div>
                <div v-if="editComment" class="card-body">
                    <textarea placeholder="Enter a comment" class="form-control" v-model="newPatentComment"></textarea>
                    <button class="btn btn-light" @click="validateComment">
                        <i class="ri-check-fill"></i>
                    </button>
                </div>
                <div v-else class="card-body comment-box">
                    <span :class="patent.patentComment ? 'strong' : ''">{{ patent.patentComment ? patent.patentComment :
                        'Comment: ...empty' }}</span>

                    <button class="btn btn-light mx-2" @click="editComment = true">
                        <i class="ri-edit-fill"></i>
                    </button>
                </div>
                <div class="form-group">
                    <input class="form-check-input mx-3" type="checkbox" id="timelineCheck" v-model="newIncludeInTimeline">
                    <label class="form-check-label" for="timelineCheck">Include in timeline</label>
                </div>
                <div v-html="this.popover_content_part_2"></div>
            </div>
            <div class="card-body row">
                <div class="col-1" role="button" @click="setActive(active - 1)" style="font-weight: bolder;">
                    <i class="ri-arrow-left-s-line" v-tooltip title="See previous image"></i>
                </div>
                <div :id="carousel_id" class="carousel slide col-9" data-bs-ride="carousel">
                    <div class="carousel-inner">
                        <div v-for="(picture, index) in pictures" :key="'picture-' + index"
                            :class="{ 'carousel-item': true, 'active': index === active }">
                            <img :src="picture" class="d-block w-100" alt="Patent image">
                        </div>
                    </div>
                </div>
                <div class="col-1" role="button" @click="setActive(active + 1)" style="font-weight: bolder;">
                    <i class="ri-arrow-right-s-line" v-tooltip title="See next image"></i>
                </div>

            </div>
        </div>

    </template>
</template>

<script>
// import { BFormRating } from 'bootstrap-vue';

export default {
    name: 'show-patent',
    props: {
        patent: Object,
        index: Number,
        maxIndex: Number,
        showDelete: Boolean,
        viewMode: String,
        whereAmI: String,
        fullPatentList: Object,
    },
    components: {
        // BFormRating
    },
    emits: ['deletePatent', 'newComment', 'changePatentOrder', 'newNplTitle', 'newNplContent'],
    data() {
        return {
            active: 0,
            confirmDelete: false,
            editComment: false,
            newPatentComment: this.patent && this.patent.patentComment || '',
            newIncludeInTimeline: this.patent && this.patent.includeInTimeline || false,
            editNplTitle: false,
            newNplTitle: this.patent && this.patent.title || '',
            editNplContent: false,
            newNplPlayer: this.patent && this.patent.player || '',
            newNplUrl: this.patent && this.patent.url || '',
            newNplInputDate: this.patent && this.patent.inputDate || '',
            newNplType: this.patent && this.patent.type || -1,
            myCriticity: this.patent && this.patent.patentCriticity || null,
            types: {
                "merge_and_acquisition": "Merge & Acquisition",
                "scientific_article": "Scientific article",
                "investment": "Investissement news",
                "partnership_and_collaboration": "Partnership & Collaboration",
                "hiring": "Hiring",
                "other": "Other"
            },
        }
    },
    watch: {
        patent() {
            if (this.patent && this.patent.patentCriticity) {
                this.myCriticity = this.patent.patentCriticity
            }
            else {
                this.myCriticity = null;
            }
            this.editComment = false
        },
        myCriticity() {
            if (this.myCriticity) {
                this.$emit("newCriticity", this.index, this.myCriticity);
            }
        },
        newIncludeInTimeline() {
            this.$emit("newIncludeInTimeline", this.index, this.newIncludeInTimeline);
        }
    },
    computed: {
        carousel_id() {
            return 'carousel-patent-images' + this.get_patent_number
        },
        has_pictures() {
            if (this.patent["IMG"] && (this.patent["IMG"].length)) {
                return true
            }
            return false
        },
        pictures() {
            if (this.patent["IMG"] && Array.isArray(this.patent["IMG"])) {
                return this.patent["IMG"]
            }
            else {
                return []
            }
        },
        get_patent_number() {
            if ("V_APL" in this.patent && this.patent.V_APL && this.patent.V_APL.length > 0) {
                return this.patent["V_APL"][0]["PUB"][0]["XPN"]
            }
            else if ("ID" in this.patent && this.patent.ID) {
                return this.patent.ID.split("_")[0]
            }
            // if patent is an NPL
            if ("title" in this.patent) {
                return this.patent.title
            }
            return ""
        },
        is_a_patent() {
            if ("ETI" in this.patent) {
                return true
            } else {
                return false
            }
        },
        is_an_npl() {
            if ("title" in this.patent) {
                return true
            } else {
                return false
            }
        },
        popover_title() {
            if (this.is_a_patent) {
                if (this.patent["ETI"].includes("<br/>")) {
                    let array = this.patent["ETI"].split("<br/>")
                    array.shift()
                    return (array.join("<br/>"))
                }
                else {
                    return this.patent["ETI"]
                }
                // if patent is an NPL
            } else if (this.is_an_npl) {
                return this.patent.title
            }
            return ""
        },
        popover_content_part_1() {
            let text = "";
            if (this.is_a_patent) {
                let status = ''
                let state = ''
                if ('ACT' in this.patent && this.patent.ACT) {
                    let re_state = /STATE=(.+?)(?= |<br\/>)/
                    state = this.patent.ACT.match(re_state)[1]
                    let re_status = /STATUS=(.+?)(?= |<br\/>)/
                    status = this.patent.ACT.match(re_status)[1]
                }
                text += "Applicants: <b>" + (this.patent.NPA || '') + "</b><br/>"
                text += "Filed on " + (this.patent.APD || 'unknown date') + "<br/>"
                text += "Status: <b>" + status + "</b><br/>"
                text += "State: <b>" + state + "</b><br/>"

                // if patent is an NPL
            } else if (this.is_an_npl) {
                if (this.patent.type && this.patent.type in this.types) {
                    text += "Type: <b>" + this.types[this.patent.type] + "</b><br/>"
                }
                text += "Player: <b>" + (this.patent.player ? this.patent.player : "... no provided player") + "</b><br/>"
                text += "Date: " + (this.patent.inputDate ? this.patent.inputDate : " ...no provided date") + "<br/>"
                if (this.patent.url) {
                    text += "<a href='" + this.patent.url + "' target='_new'>URL</a>"
                }
            }

            return text
        },
        popover_content_part_2() {
            let text = "";
            if (this.is_a_patent) {
                text += "Inventors: " + (this.patent.IN || '') + "<br/>"
                let publications = []
                if ("V_APL" in this.patent && this.patent.V_APL && this.patent.V_APL.length > 0) {
                    publications = this.patent["V_APL"][0]["PUB"]
                }
                for (const publication of publications) {
                    text += "<a href='" + publication["PDF_LINK"] + "' target='_new'>"
                    text += publication["DATE"] + ": " + publication["KIND"] + "</a>"
                    text += " (" + publication["STG"] + ") <br/>"
                }
                if ("XAP" in this.patent) {
                    text += "Application no.: <b>" + this.patent["XAP"] + "</b><br/>"
                }
            }
            // if patent is an NPL, no data in the second part for the moment

            return text
        },
        has_also() {
            if (this.also_present_in && this.also_present_in.length)
                return true
            return false
        },
        also_present_in() {
            let key = this.patent["APID"]
            let also_present_array = []
            if (key in this.fullPatentList) {
                let places_list = this.fullPatentList[key]
                for (let place of places_list) {
                    if (place != this.whereAmI) {
                        also_present_array.push(place)
                    }
                }
            }
            return also_present_array
        },
        also_present_in_string() {
            if (this.also_present_in && this.also_present_in.length > 0) {
                return "Also present in " + this.also_present_in.join(' <b>&&</b> ').replace('Project root |', '').replace('Project root', 'Root level')
            }
            return ""
        },
    },
    methods: {
        setActive(index) {
            let active = index;

            if (index === this.pictures.length) active = 0;
            else if (index === -1) active = this.pictures.length - 1;

            this.active = active;
        },
        toggleDelete() {
            this.confirmDelete = !this.confirmDelete
        },
        deletePatent() {
            this.$emit("deletePatent", this.index);
            this.confirmDelete = false;
        },
        validateComment() {
            this.$emit("newComment", this.index, this.newPatentComment)
            this.editComment = false
        },
        newPatentOrder(value) {
            this.$emit("changePatentOrder", this.index, this.index + value)
        },
        validateNewNplTitle() {
            this.$emit("newNplTitle", this.index, this.newNplTitle)
            this.editNplTitle = false
        },
        validateNewNplContent() {
            this.$emit("newNplContent", this.index, this.newNplPlayer, this.newNplUrl, this.newNplInputDate, this.newNplType)
            this.editNplContent = false
        }
    }
}


</script>

<style scoped>
.do_underline:hover {
    text-decoration: none;
    color: blue;
    border-bottom: 1px solid blue;
    cursor: pointer;
}

.patent_number {
    font-weight: bold;
}

.change-order {
    cursor: pointer
}

.card-body {
    text-align: left;
}

.comment-box {
    border: 1px dashed black;
    background-color: #F1F1F1;
    margin-left: 8px;
    margin-right: 8px;
    margin-top: 12px;
    margin-bottom: 12px;
    font-weight: bolder;
}

.form-check-input {
    background-color: #eee;
}
</style>