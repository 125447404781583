<template>
    <div>
        <span v-if="projectName">Project: {{projectName}} 
            <i class="ri-delete-bin-2-line ms-1 me-3 do_underline" @click="toggleDelete"></i>&#32;
            <button v-if="confirmDelete" class="btn btn-light" type="button" @click="deleteProject">Delete project {{
                projectName }}? This action cannot be reversed.
            </button>
            <button v-if="confirmDelete" class="btn btn-light" type="button" @click="toggleDelete">Cancel</button>
        </span>
        <button @click="toggleLoadInput" class='button' :style="{'padding': '0px 20px', 'border-left': '1px solid lightgrey'}" v-tooltip title="Load existing projects"><i class="ri-file-add-line"></i></button>  
        <button @click="toggleSaveInput" class='button' :style="{'padding': '0px 20px', 'border-left': '1px solid lightgrey'}" v-tooltip title="Save project"><i class="ri-file-copy-line"></i></button> 
        <button @click="exportTreeToCSV" class='button' :style="{'padding': '0px 20px', 'border-left': '1px solid lightgrey'}" v-tooltip title="Download"><i class="ri-download-2-line"></i></button>
        <button @click="toggleShareProject" class='button' :style="{'padding': '0px 20px', 'border-left': '1px solid lightgrey'}" v-tooltip title="Share"><i class="ri-share-line"></i></button>
        <button @click="goToLogout" class='button' :style="{'padding': '0px 20px', 'border-left': '1px solid lightgrey'}" v-tooltip title="Logout"><i class="ri-logout-box-line"></i></button>
        <div v-if="showInputProjectName" style="position: absolute; right: 0; width: 50%">
            <input style="height: 80px" v-model="projectName" type="text" class="form-control" placeholder="Please enter the name of your project">
            <button 
                style="float:right; background-color: grey; color: black;" 
                class="btn btn-light" 
                type="button" 
                @click="showInputProjectName=false"
            >Cancel</button>
            <button 
                class="btn btn-light" 
                type="button" 
                @click="saveJson"
                :style="{
                    'cursor': (projectName === '' ? 'not-allowed' : 'pointer'), 
                    'float': 'right', 
                    'background-color': (projectName === '' ? 'lightgrey' : 'orangered'), 
                    'color': 'white'
                }"
            >Save</button>
        </div>
        <div v-if="showShareProject" style="position: absolute; right: 0; width: 50%">
            <input style="height: 40px" v-model="projectName" type="text" class="form-control" placeholder="Please enter the name of your project">
            <input style="height: 40px" v-model="shareEmail" type="email" class="form-control" placeholder="Please enter the email of your colleague">
            <button 
                style="float:right; background-color: grey; color: black;" 
                class="btn btn-light" 
                type="button" 
                @click="showShareProject=false"
            >Cancel</button>
            <button 
                class="btn btn-light" 
                type="button" 
                @click="shareTree"
                :style="{
                    'cursor': (projectName === '' || email === '' ? 'not-allowed' : 'pointer'), 
                    'float': 'right', 
                    'background-color': (projectName === '' || email === '' ? 'lightgrey' : 'orangered'), 
                    'color': 'white'
                }"
            >Share</button>
        </div>
        <!-- List of floating buttons -->
        <div v-if="showFloatingButtons" class="floating-buttons">
            <button v-for="project in projects" :key="project._id" @click="loadTree(project)">
                {{ project.projectName }}
            </button>
            <button class="close-button" @click="showFloatingButtons=false">
                Close
            </button>
        </div>
    </div>
</template>

<script>
    import {is_a_patent, is_an_npl} from '@/functions/treeutils.js'
    import { deleteProject, updateProject, listProjects, shareProject} from '@/services/apiService.js'
    import router from '@/router'

    export default { 
        name: 'export-save-load',
        props: ['tree'],
        data() {
            return {
                showJSON: false,
                showInput: false,
                showFloatingButtons: false,
                showInputProjectName: false,
                showShareProject: false,
                confirmDelete: false,
                treeJSON: '',
                projectName: '',
                shareEmail: '',
                projects:[]
            }
        },
        methods: {
            toggleDelete() {
                this.confirmDelete = !this.confirmDelete
            },
            async deleteProject() {
                if(this.projectName){
                    try {
                        let userId = this.$store.state.userId;
                        const userEmail = this.$store.state.email.toLowerCase();
                        let projectName = this.projectName;
                        const nameLength = projectName.length - 9
                        
                        if (nameLength > 0 && projectName.substring(nameLength) == ' (shared)'){
                            userId = userEmail;
                            projectName = projectName.substring(0, nameLength)
                        }

                        const data = {
                            projectName: projectName,
                            userId: userId
                        };

                        let response =  await deleteProject(data);
                        this.confirmDelete = false;
                        this.projectName = ''
                        console.log(response._id)
                    } catch (error) {
                        // Handle error
                        console.error('Error deleting project:', error);
                    }
                }
            },
            async shareTree() {
                if (this.projectName && this.shareEmail){
                    try {
                        const jsonData = JSON.stringify(this.tree);
                        const projectName = this.projectName;
                        const shareEmail = this.shareEmail;

                        const data = {
                            userShareEmail: shareEmail,
                            projectName: projectName,
                            jsonData: jsonData
                        };

                        let response =  await shareProject(data);
                        this.showShareProject = false;
                        console.log(response._id)
                    } catch (error) {
                        // Handle error
                        console.error('Error saving project:', error);
                    }
                }
            },
            goToLogout() {
                router.push('/logout')
            },
            exportTreeToCSV() {
                // Initialize the key-value object for property mapping
                const patentPropertiesToExport = {
                    APID: 'APID',   
                    ETI: 'Title',
                    PA: 'Applicant',
                    NPA: 'Normalized Applicant',
                    APD: 'Application date', 
                    IN: 'Inventors', 
                    PDF: 'PDF',
                };

                const nplPropertiesToExport = {
                    APID: 'APID',
                    title: 'Title',
                    player: 'Applicant',
                    player2: 'Normalized Applicant',
                    inputDate: 'Application date',
                }
                let csv = 'Place,' + Object.values(patentPropertiesToExport).join(',') + '\n';

                // Function to escape special characters for CSV
                const escapeCSVValue = (value) => {
                    if (typeof value === 'string') {
                    return `"${value.replace(/"/g, '""')}"`;
                    }
                    return value;
                };

                // Function to get the values of a patent, or empty string if the property doesn't exist
                const getPatentValue = (patent, property) => {
                    if (property == 'PDF') {
                    return patent["V_APL"][0]["PUB"][0]["PDF_LINK"] || '';
                    }
                    else {
                    return patent[property] !== undefined ? patent[property] : '';
                    }
                };

                // Function to recursively build the "whereAmI" value
                const buildWhereAmI = (node, parentWhereAmI = '') => {
                    if (node.label) {
                        if (parentWhereAmI) {
                            return parentWhereAmI + ' | ' + node.label;
                        } else {
                            return node.label;
                        }
                    }
                    return parentWhereAmI;
                };

                // Convert tree data to CSV
                const convertToCSV = (node, parentWhereAmI = '') => {
                    if (node.patents && node.patents.length > 0) {
                        node.patents.forEach(patent => {
                            const whereAmI = buildWhereAmI(node, parentWhereAmI);

                            let row = []
                            if(is_a_patent(patent)) {
                                row = [whereAmI, ...Object.keys(patentPropertiesToExport).map(property => escapeCSVValue(getPatentValue(patent, property)))];
                            } else if (is_an_npl(patent)) {
                                row = [whereAmI, ...Object.keys(nplPropertiesToExport).map(property => escapeCSVValue(getPatentValue(patent, property)))];
                            }


                            csv += row.join(',') + '\n';
                        });
                    } else {
                        // If a node has no patents, include a line with "whereAmI" and empty patent columns
                        const whereAmIRow = [buildWhereAmI(node, parentWhereAmI)];
                        whereAmIRow.push(...new Array(Object.keys(patentPropertiesToExport).length - 1).fill(''));
                        csv += whereAmIRow.map(value => escapeCSVValue(value)).join(',') + '\n';
                    }

                    if (node.nodes) {
                        node.nodes.forEach(child => convertToCSV(child, buildWhereAmI(node, parentWhereAmI)));
                    }
                };

                // Start converting from the root node
                convertToCSV({
                    patents: this.tree.patents,
                    nodes: this.tree.nodes,
                    label: this.tree.label,
                });

                // Create a data URI for the CSV
                const csvDataUri = `data:text/csv;charset=utf-8,${encodeURIComponent(csv)}`;

                // Create a hidden anchor element to trigger the download
                const link = document.createElement('a');
                link.setAttribute('href', csvDataUri);
                link.setAttribute('download', 'patent_data.csv');
                link.style.display = 'none';

                // Trigger the download
                document.body.appendChild(link);
                link.click();
                document.body.removeChild(link);
            },
            async copyAndCloseJSON() {
                try {
                    await navigator.clipboard.writeText(JSON.stringify(this.tree));
                    // await navigator.clipboard.writeText(this.treeJSON);
                    alert('Copied');
                } catch($e) {
                    alert('Cannot copy');
                } 
                this.showJSON=false
            },
            async saveJson() {
                if (this.projectName){
                    try {
                        const userId = this.$store.state.userId;
                        const jsonData = JSON.stringify(this.tree);
                        const projectName = this.projectName;

                        const data = {
                            userId: userId,
                            projectName: projectName,
                            jsonData: jsonData
                        };

                        let response =  await updateProject(data);
                        this.showInputProjectName = false;
                        console.log(response._id)
                    } catch (error) {
                        // Handle error
                        console.error('Error saving project:', error);
                    }
                }
            },
            toggleSaveInput() {
                this.showInputProjectName = !this.showInputProjectName
            },
            async toggleLoadInput() {
                if(!this.showFloatingButtons){
                    try {
                        const userId = this.$store.state.userId;

                        const data = {
                            userId: userId,
                        };

                        // Call the API service method to list projects for the user
                        await listProjects(data).then(response => {
                            // Handle the response, which should contain the list of projects
                            // For example, you can store the projects in a component data property
                            // and then display them in a modal
                            this.projects = response; // Assuming response contains the project data
                            this.showFloatingButtons = true; // Show the modal containing the projects
                        }).catch(error => {
                            // Handle error
                            console.error('Error listing projects:', error);
                        });

                        const data2 = {
                            userId: this.$store.state.email.toLowerCase()
                        }
                        console.log(data2)
                        // Call the API service method to list projects for the user
                        listProjects(data2).then(response => {
                            // Handle the response, which should contain the list of projects
                            // For example, you can store the projects in a component data property
                            // and then display them in a modal
                            console.log(response)
                            for (let i=0; i<response.length;i++){
                                response[i].projectName += " (shared)"
                                this.projects.push(response[i])
                            }
                            this.showFloatingButtons = true; // Show the modal containing the projects
                        }).catch(error => {
                            // Handle error
                            console.error('Error listing projects:', error);
                        });

                    } catch (error) {
                        // Handle error
                        console.error('Error listing projects:', error);
                    }
                }
                else {
                    this.showFloatingButtons = false;
                }
            },
            toggleShareProject() {
                this.showShareProject = !this.showShareProject
            },
            loadTree(project) {
                this.$emit("load-tree", JSON.parse(project.jsonData));
                this.projectName = project.projectName
                this.showFloatingButtons=false
            },
        }
    }
</script>

<style scoped>
.floating-buttons {
  position: absolute;
  top: 40px;
  right: 50px;
  width: 100%;
  text-align: center;
}

.floating-buttons button {
  margin: 0 5px;
  padding: 5px 10px;
  background-color: #007bff;
  color: white;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.floating-buttons .close-button {
    background-color: white;
    color: #007bff;
}

.do_underline:hover {
    text-decoration: none;
    color: blue;
    border-bottom: 1px solid blue;
    cursor: pointer;
}
</style>