<template>
    <div class="row radioselect">
        <!-- Radio buttons for selecting applicant options -->
        <div v-for="(label, option) in this.options" :key="option" class="form-check">
            <input class="form-check-input" type="radio" v-model="myApplicantOptions" :id="option" :value="option">
            <label class="form-check-label" for="top10">{{ label }}</label>
            <button 
                id="editApplicantListing" 
                v-if="option == 'selectedListing' && myApplicantOptions == 'selectedListing' && !isApplicantListingEdited" 
                class="btn btn-outline-secondary btn-sm"
                @click="isApplicantListingEdited=true"
            >Edit</button>
        </div>
        <!-- Dropdown for selecting specific applicants from the list -->
        <select v-model="mySelectedApplicants" class="form-select" multiple v-if="isApplicantListingEdited" size="10">
            <option disabled>Select all applicants of interest </option>
            <option v-for="applicant in total_applicants" :value="applicant.applicant" :key="applicant">{{applicant.applicant + ' (' + applicant.value + ')'}}</option>
        </select>
        <!-- Validate button for confirming selected applicants -->
        <button v-if="isApplicantListingEdited" class="btn btn-outline-primary btn-sm" @click="validateSelectedApplicants">Validate</button>

    </div>
</template>

<script>

    export default { 
        name: 'choose-applicant-options',
        props: ['applicantOptions', 'total_applicants', 'selected_applicants'],
        data() {
            return {
                myApplicantOptions: this.applicantOptions,
                mySelectedApplicants: this.selected_applicants,
                isApplicantListingEdited: false,
                options: {
                    "top10": "Top 10 applicants",
                    "top5": "Top 5 applicants",
                    "selectedListing": "Selected listing"
                }
            }
        },
        methods: {
            // Emitting new applicant option to parent component
            changeApplicantOption() {
                this.$emit('newApplicantOption', this.myApplicantOptions)
                this.isApplicantListingEdited = false
            },
            // Emitting new selected applicants to parent component
            validateSelectedApplicants() {
                this.$emit('newSelectedApplicants', this.mySelectedApplicants)
                this.isApplicantListingEdited = false
            },
            emitNewSelectedApplicants() {
                if (this.myApplicantOptions === 'top10') {
                    this.mySelectedApplicants = this.total_applicants.slice(0,10).map((e) => e.applicant)
                    this.$emit('newSelectedApplicants', this.mySelectedApplicants)
                    this.isApplicantListingEdited = false;
                } else if (this.myApplicantOptions === 'top5') {
                    this.mySelectedApplicants = this.total_applicants.slice(0,5).map((e) => e.applicant)
                    this.$emit('newSelectedApplicants', this.mySelectedApplicants)
                    this.isApplicantListingEdited = false;
                } else if (this.myApplicantOptions === 'selectedListing'){
                    this.isApplicantListingEdited = true;
                }
            }
        },
        watch: {
            applicantOptions(){
                this.myApplicantOptions = this.applicantOptions
            },
            myApplicantOptions() {
                this.emitNewSelectedApplicants()
            },
            total_applicants() {
                this.emitNewSelectedApplicants()
            }
        }
    }
</script>



<style scoped>
</style>
