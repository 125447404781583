<template>
    <div>
        <form @submit.prevent>
        <div class="form-group row">
            <div class="col-md-1" @click="toggleInput">
                <button 
                    class="btn btn-sm btn-light rounded-pill"
                    v-tooltip
                    :title="'Add a node to ' + parentValue"
                >
                    <span><i class="ri-folder-line mx-2"></i></span>
                    <span class="badge rounded-pill bg-info" style="margin-left: -15px; margin-right: 10px; font-size: 5px">+</span>
                    <span>Add node</span>
                </button>
            </div>
            <div v-show="showInput" class="col-md-4" style="margin-left: 80px">
                <input v-model="nodeValue" ref="nodeInput" type="text" class="form-control" placeholder="Please name the node">
            </div>
            <div v-show="showInput" class="col-md-4">
                <button class="btn btn-light" type="button" @click="addNewNode">Add node to '{{ this.parentValue }}'</button>
            </div>
        </div>
        </form>
    </div>
</template>

<script>
    // import { ref, nextTick } from 'vue';

    export default { 
        name: 'new-node',
        props: ['parentValue', 'resetNode'],
        data() {
            return {
                nodeValue: "",
                showInput: false
            }
        },
        methods: {
            addNewNode() {
                this.$emit("addNewNode", this.nodeValue);
                this.nodeValue = ""
                this.showInput = false
            },
            async toggleInput() {
                this.showInput = !this.showInput
                if (this.showInput) {
                    this.$nextTick(function () {
                        if (this.$refs["nodeInput"]) {this.$refs["nodeInput"].focus();}
                    })
                    this.$emit("resetToggle", "node");
                }
            }
        },
        watch: {
            resetNode(){
                if (this.resetNode === true) {
                    this.showInput = false;
                    this.nodeValue = "";
                }
            }
        }
    }
</script>

<style scoped>

button {
    display: inline-block;
    white-space: nowrap;
}

</style>