<template>
    <div class="login">
      <h2>Login</h2>
      <form @submit.prevent="submitLoginForm" class="login-form">
        <div class="form-group">
            <label for="email">Email:</label>
            <input type="email" id="email" v-model="email" required>
        </div>
        <div class="form-group">
            <label for="password">Password:</label>
            <input type="password" id="password" v-model="password" required>
        </div>
        <button type="submit">Login</button>
      </form>
      <p>Don't have an account? <router-link to="/register">Register</router-link></p>
    </div>
</template>
  
<script>
// import { authService } from '@/services/apiService'; // Adjust import path based on your project structure
import { mapActions } from 'vuex';

export default {
	data() {
		return {
			email: '',
			password: ''
		};
	},
	methods: {
		...mapActions(['login']),
		async submitLoginForm() {
			// Call Vuex action to login user
			await this.login({ email: this.email, password: this.password });
			// Redirect user after successful login
			this.$router.push('/');
		}
	},
  beforeCreate() {
    localStorage.removeItem('user')
    localStorage.removeItem('email')
    localStorage.removeItem('isAuthenticated')
    localStorage.removeItem('token')
    this.$store.state.isAuthenticated = false
    this.$store.state.email = null
    this.$store.state.userId = null
    this.$store.state.token = null
  }
};
  </script>
  
  <style scoped>
  .login {
    display: flex;
    flex-direction: column;
    align-items: center; /* Vertically align items */
    max-width: 400px;
    margin: 0 auto;
    margin-top: 200px; /* Adjust margin as needed */
  }
  .form-group {
    display: flex;
    flex-direction: column;
    width: 300px; 

  }
  .login form {
    display: flex;
    flex-direction: column;
  }
  .login form div {
    margin-bottom: 10px;
  }
  .login form label {
    font-weight: bold;
  }
  .login form input {
    padding: 5px;
    font-size: 1rem;
  }
  .login form button {
    padding: 8px 15px;
    background-color: #007bff;
    color: #fff;
    border: none;
    cursor: pointer;
  }
  .login form button:hover {
    background-color: #0056b3;
  }
  p {
    margin-top: 15px;
  }

  </style>
  