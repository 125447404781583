import { Tooltip, Popover } from 'bootstrap'

var tooltip_options = {
    html: true,
    trigger: 'hover',
    placement: 'top',
}
var popover_options = {
    html: true, 
    trigger: 'focus'
}
export const tooltip = {    
    mounted(el) {
        new Tooltip(el, tooltip_options)
    }
}
export const popover = {
    mounted(el) {
        new Popover(el, popover_options)
    }
}
