<template>

    <div class="tree-menu">
        <!-- <link href="https://cdn.jsdelivr.net/npm/remixicon@3.5.0/fonts/remixicon.css" rel="stylesheet"> -->
        <div class="label-wrapper" style="line-break: anywhere;">
            <div>
                <span :class="labelClasses" class="mx-3">
                    <span v-if="showLabelInput" ref="el">
                        <input v-model="newLabel" type="text" class="form-control" @click.stop
                            placeholder="Please enter the new label">
                        <button class="btn btn-light" @click="validateNewLabel">
                            <i class="ri-check-fill"></i>
                            Save
                        </button>
                    </span>
                    <span v-show="!showLabelInput" @click="toggleChildren">
                        <i v-show="!showChildren && myNodes && myNodes.length && myNodes.length > 0" class="ri-add-box-line"
                            v-tooltip title="Show children nodes"></i>
                        <i v-show="showChildren && myNodes && myNodes.length && myNodes.length > 0" class="ri-subtract-fill"
                            v-tooltip title="Hide children nodes"></i>
                        {{ myLabel }}
                        <i class="ri-edit-fill" @click.stop @click="editLabel" v-tooltip title="Change label"></i>
                    </span>
                </span>
                <span v-if="this.myPatents.length > 0"> <b>{{ this.myPatents.length }} patent(s) (and Npl)</b>
                    <span v-if="changeOrder" class="changeOrder">
                        <button v-if="changeOrder && (numberOfParentNodes > 0 && index > 0)"
                            class="btn btn-sm btn-light rounded-pill ">
                            <span class="align-middle m-1 change-order" @click="newNodeOrder(-1); changeOrder = false;">
                                <i class="ri-arrow-up-double-line"></i>
                                Up
                            </span>
                        </button>
                        <button v-if="changeOrder && (numberOfParentNodes > 0 && index < (numberOfParentNodes - 1))"
                            class="btn btn-sm btn-light rounded-pill">
                            <span class="align-middle m-1 change-order" @click="newNodeOrder(+1); changeOrder = false;">
                                <i class="ri-arrow-down-double-fill"></i>
                                Down
                            </span>
                        </button>
                    </span>
                    <span
                        v-if="changeOrder == false && numberOfParentNodes > 0 && (index > 0 || index < (numberOfParentNodes - 1))"
                        class="changeOrder">
                        <button class="btn btn-sm btn-light rounded-pill" @click="changeOrder = true;">
                            <i class="ri-arrow-up-double-line"></i>
                            <i class="ri-arrow-down-double-fill"></i>
                            Change order
                        </button>
                    </span>
                    <span v-if="this.viewMode == 'Edit'"><b>: </b><br /></span>
                </span>
                <span v-if="this.viewMode == 'Edit'">
                    <show-patent v-for="(patent, index) in this.myPatents" :key="patent.APID" :patent="patent"
                        :index="index" :maxIndex="this.maxIndex" :showDelete=true
                        @deletePatent="deletePatent"
                        @newComment="updateComment" 
                        @newCriticity="updateCriticity"
                        @newIncludeInTimeline="updateIncludeInTimeline"
                        @newNplTitle="updateNplTitle" 
                        @newNplContent="updateNplContent"
                        @changePatentOrder="changePatentOrder" :viewMode='this.viewMode' :whereAmI='this.whereAmI'
                        :fullPatentList='this.myFullPatentList'></show-patent>
                </span>
            </div>
        </div>
        <div class="container" v-if="this.viewMode == 'View'">
            <div class="row">
                <div v-for="(patent, index) in this.myPatents" :key="patent.APID" class="col-3">

                    <show-patent :patent="patent" :index="index" :maxIndex="this.maxIndex" :showDelete=true
                        @deletePatent="deletePatent" 
                        @newComment="updateComment" 
                        @newCriticity="updateCriticity"
                        @newIncludeInTimeline="updateIncludeInTimeline"
                        @newNplTitle="updateNplTitle" 
                        @newNplContent="updateNplContent"
                        @changePatentOrder="changePatentOrder" :viewMode='this.viewMode' :whereAmI='this.whereAmI'
                        :fullPatentList='this.myFullPatentList'>
                    </show-patent>
                </div>
            </div>
        </div>
        <div :style="{
            'display': 'flex',
            'justify-content': 'right',
            'gap': '5px'
        }">
            <span :class="labelClasses" v-if="this.viewMode == 'Edit'">
                <new-node @addNewNode="addNewNode" :parentValue="myLabel" @resetToggle="resetToggle" :resetNode="resetNode"></new-node>
            </span>
            <span :class="labelClasses" v-if="this.viewMode == 'Edit'">
                <delete-node v-if="depth > 0" @deleteNode="deleteNode" :parentValue="myLabel" @resetToggle="resetToggle" :resetDeleteNode="resetDeleteNode"></delete-node>
            </span>
            <span :class="labelClasses" v-if="this.viewMode == 'Edit'">
                <new-patent @addNewPatent="addNewPatent" :parentValue="myLabel" @resetToggle="resetToggle" :resetPatent="resetPatent"></new-patent>
            </span>
            <span :class="labelClasses" v-if="this.viewMode == 'Edit'">
                <new-npl @addNewNPL="addNewNPL" :parentValue="myLabel" @resetToggle="resetToggle" :resetNpl="resetNpl"></new-npl>
            </span>
        </div>
        <div v-if="showChildren">
            <div v-for="(node, i) in myNodes" :key="node.label + i">
                <tree-menu :nodes="node.nodes" :patents="node.patents" :label="node.label" :depth="depth + 1"
                    :whereAmI="this.whereAmI + ' | ' + node.label" :index="i" :viewMode='this.viewMode'
                    :fullTree='this.myFullTree' :fullPatentList='this.myFullPatentList'
                    :numberOfParentNodes="this.myNodes.length" @change-node-order="changeNodeOrder"
                    @update-node-in-parent="updateNodeinParent" @delete-node-in-parent="deleteNodeInParent"></tree-menu>
            </div>
        </div>
    </div>
</template>


<script>

import NewNode from './NewNode.vue'
import DeleteNode from './DeleteNode.vue'
import NewPatent from './NewPatent.vue'
import ShowPatent from './ShowPatent.vue'
import NewNpl from './NewNPL.vue'
import 'remixicon/fonts/remixicon.css'

function BuildPatentList(patent_list, thisNode) {
    if (thisNode && thisNode["patents"] && Array.isArray(thisNode["patents"])) {
        for (let patent of thisNode["patents"]) {
            const key = patent["APID"]
            const value = patent["whereAmI"]

            if (!(key in patent_list)) {
                patent_list[key] = []
            }
            patent_list[key].push(value)
        }
    }
    if (thisNode && thisNode.nodes && Array.isArray(thisNode.nodes)) {
        for (let node of thisNode.nodes) {
            patent_list = BuildPatentList(patent_list, node)
        }
    }
    return patent_list;
}

export default {
    props: ['label', 'nodes', 'patents', 'depth', 'whereAmI', 'index', 'viewMode', 'fullTree', 'fullPatentList', 'numberOfParentNodes'],
    data() {
        return {
            showChildren: false,
            changeOrder: false,
            myLabel: this.label || '',
            myNodes: this.nodes || [],
            myPatents: this.patents || [],
            showLabelInput: false,
            newLabel: this.label || '',
            resetNode: false,
            resetDeleteNode: false,
            resetPatent: false,
            resetNpl: false
        }
    },
    name: 'tree-menu',
    components: { NewNode, NewPatent, ShowPatent, DeleteNode, NewNpl },
    computed: {
        myFullTree() {
            return this.depth === 0
                ? { label: this.myLabel, nodes: this.myNodes, patents: this.myPatents }
                : this.fullTree;
        },
        myFullPatentList() {
            return this.depth === 0
                ? BuildPatentList({}, { label: this.myLabel, nodes: this.myNodes, patents: this.myPatents })
                : this.fullPatentList;
        },
        // indent() {
        //     return { transform: `translate(${this.depth * 50}px)` };
        // },
        labelClasses() {
            return { 'has-children': this.myNodes, 'node-label': true }
        },
        maxIndex() {
            return this.myPatents.length || 0
        }
    },
    watch: {
        label() {
            this.myLabel = this.label
        },
        nodes() {
            this.myNodes = this.nodes
        },
        patents() {
            this.myPatents = this.patents
        }
    },
    methods: {
        newNodeOrder(value) {
            this.$emit("change-node-order", this.index, this.index + value)
        },
        changeNodeOrder(old_index, new_index) {
            let tmp = this.myNodes.splice(old_index, 1)
            this.myNodes.splice(new_index, 0, tmp[0]);
            this.emitToUpper();
        },
        changePatentOrder(old_index, new_index) {
            let tmp = this.myPatents.splice(old_index, 1)
            this.myPatents.splice(new_index, 0, tmp[0]);
            this.emitToUpper();
        },
        emitToUpper() {
            if (this.depth > 0) {
                this.$emit('update-node-in-parent', this.index, { "label": this.myLabel, "nodes": this.myNodes, "patents": this.myPatents })
            }
            else {
                this.$emit('update-tree', { "label": this.myLabel, "nodes": this.myNodes, "patents": this.myPatents })
            }
        },
        editLabel() {
            this.showLabelInput = true
        },
        validateNewLabel() {
            this.myLabel = this.newLabel;
            this.showLabelInput = false;
            this.emitToUpper();
        },
        toggleChildren() {
            this.showChildren = !this.showChildren;
        },
        addNewNode(value) {
            this.myNodes.push({ "label": value, nodes: [] })
            this.showChildren = true
            this.emitToUpper();
        },
        deleteNode(value) {
            if (value && this.depth > 0) {
                this.$emit('delete-node-in-parent', this.index)
            }
        },
        updateNodeinParent(i, changedNode) {
            this.myNodes[i] = changedNode;
            this.emitToUpper();
        },
        deleteNodeInParent(i) {
            this.myNodes.splice(i, 1)
            this.emitToUpper();
        },
        addNewPatent(newPatentObj) {
            newPatentObj["whereAmI"] = this.whereAmI
            newPatentObj["order"] = this.maxIndex + 1
            this.myPatents.push(newPatentObj)
            this.emitToUpper();
        },
        addNewNPL(newNPLObj) {
            newNPLObj["whereAmI"] = this.whereAmI
            newNPLObj["order"] = this.maxIndex + 1
            newNPLObj["APID"] = newNPLObj.title
            this.myPatents.push(newNPLObj)
            this.emitToUpper();
        },
        deletePatent(index) {
            this.myPatents.splice(index, 1)
            this.emitToUpper();
        },
        updateComment(index, newValue) {
            this.myPatents[index]["patentComment"] = newValue
            this.emitToUpper();
        },
        updateCriticity(index, newValue) {
            this.myPatents[index]["patentCriticity"] = newValue
            this.emitToUpper();
        },
        updateIncludeInTimeline(index, newIncludeInTimeline){
            this.myPatents[index]["includeInTimeline"] = newIncludeInTimeline
            this.emitToUpper();
        }, 
        updateNplTitle(index, newValue) {
            this.myPatents[index]["title"] = newValue;
            this.emitToUpper();
        },
        updateNplContent(index, newPlayer, newUrl, newInputDate, newNplType) {
            this.myPatents[index]["player"] = newPlayer;
            this.myPatents[index]["url"] = newUrl;
            this.myPatents[index]["inputDate"] = newInputDate;
            this.myPatents[index]["type"] = newNplType;
            this.emitToUpper();
        },
        findPatentIndexForOrderValue(order_value) {
            for (let index = 0; index < this.myPatents.length; index++) {
                if (this.myPatents[index].order == order_value) {
                    return index
                }
            }
        },
        sendToChildren(value) {
            if(value == "patent"){
                this.resetNode = true; 
                this.resetDeleteNode = true; 
                this.resetNpl = true; 
            } else if (value === "node") {
                this.resetPatent = true; 
                this.resetDeleteNode = true; 
                this.resetNpl = true; 
            } else if (value === "deleteNode") {
                this.resetPatent = true; 
                this.resetNode = true; 
                this.resetNpl = true; 
            } else if (value === "nodeNpl") {
                this.resetPatent = true; 
                this.resetNode = true; 
                this.resetDeleteNode = true; 
            }
        },
        async resetToggle(value){
            await this.sendToChildren(value)
            this.resetPatent = false; 
            this.resetNode = false; 
            this.resetDeleteNode = false; 
            this.resetNpl = false; 
        }
    },
}
</script>
  
<style scoped>
.tree-menu {
    box-shadow: 0 2px 8px rgba(24, 99, 107, 0.26);
    margin: 3rem;
    border-radius: 10px;
    padding: 1rem;
    background-color: #eaf0f3;
    color: black;
    text-align: center;
    font-size: large;
}

.change-order {
    cursor: pointer;
    font-weight: bold;
}

.changeOrder {
    float: right;
    margin-right: 20px;
}

.node-label {
    font-weight: bolder;
}
</style>