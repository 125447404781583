// import Vue from 'vue'
import { createRouter, createWebHistory } from 'vue-router'; // Import createRouter and createWebHistory
import HomeView from '../views/HomeView.vue';
import LoginView from '../views/LoginView.vue';
import RegisterView from '../views/RegisterView.vue';
import ConfirmRegistration from '../views/ConfirmRegistration.vue';
import store from '@/store';

const routes = [
    {
        path: '/',
        name: 'Home',
        component: HomeView,
        meta: { requiresAuth: true } // Example of a route requiring authentication
    },
    {
        path: '/login',
        name: 'Login',
        component: LoginView
    },
    {
        path: '/register',
        name: 'Register',
        component: RegisterView
    },
    {
        path: '/confirm-registration',
        name: 'ConfirmRegistration',
        component: ConfirmRegistration
    },
    // Catch-all route for unauthorized access
    {
        path: '/:catchAll(.*)',
        redirect: '/login'
    }
]

const router = createRouter({
    history: createWebHistory(),
    routes
});

router.beforeEach((to, from, next) => {
    if (to.matched.some(record => record.meta.requiresAuth)) {
        // Check if user is authenticated
        if (!store.state.isAuthenticated) {
            // User is not authenticated, redirect to login page
            next('/login');
        } else {
            // User is authenticated, allow navigation
            next();
        }
    } else {
        // Route doesn't require authentication, allow navigation
        next();
    }
});

export default router; // Export the router instance
