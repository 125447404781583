<template>
     <div> 
        <div class="form-group row">
            <div class="col-md-2" @click="toggleInput">
                <button 
                    class="btn btn-sm btn-light rounded-pill"
                    v-tooltip
                    :title="'Add a NPL to ' + parentValue"
                >
                    <span><i class="ri-profile-line mx-2"></i></span>
                    <span class="badge rounded-pill bg-info" style="margin-left: -15px; margin-right: 10px; font-size: 5px">+</span>
                    <span>Add NPL</span>
                </button>
            </div>
            <template v-if="showInput">
                <div class="col-md-8" style="margin-left: 20px;">
                    <div class="form-group">
                        <label for="title">Title</label>
                        <input type="input" class="form-control" ref="title" v-model="title" placeholder="Add Title">
                        <small id="titleHelp" class="form-text text-muted">Name of the product or any non-patent-litterature (NPL) you would like to add</small>
                        <small v-if="!validTitle" id="titleError" class="form-text text-danger">Title cannot be empty</small>
                    </div>
                    <div class="form-group">
                        <label for="type">Type</label>
                        <select class="form-select" v-model="type" aria-label="Default select example">
                            <option disabled value="-1">Choose Type of NPL</option> /  /  /  / 
                            <option v-for="(value, key) in types" :value="key" :key="key">{{ value }}</option>
                        </select>
                        <small v-if="!validType" id="typeError" class="form-text text-danger">You must choose a type</small>
                    </div>
                    <div class="form-group">
                        <label for="player">Player (if several, separate using slash / )</label>
                        <input type="input" class="form-control" id="player" v-model="player" placeholder="Add Player">
                    </div>
                    <div class="form-group">
                        <label for="url">URL</label>
                        <input type="input" class="form-control" id="url" v-model="url" placeholder="Add URL if any">
                    </div>
                    <div class="form-group">
                        <label for="doi">DOI</label>
                        <input type="input" class="form-control" id="doi" v-model="doi" placeholder="Add DOI if any">
                    </div>
                    <div>
                        <label for="date">Date</label>
                        <input type="input" class="form-control" id="date" v-model="inputDate" placeholder="Date in the format YYYY-MM-DD or blank">
                        <small id="dateHelp" v-if="!validDate" class="form-text text-danger">Please enter a valid date in the format YYYY-MM-DD or leave blank</small>
                    </div>
                    <div class="form-group">
                        <label for="url">Comment</label>
                        <input type="input" class="form-control" id="patentComment" v-model="patentComment" placeholder="Add a comment if any">
                    </div>
                    <div class="form-group">
                        <label for="url">Image URL</label>
                        <input type="input" class="form-control" id="imageUrl" v-model="imageUrl" placeholder="Add the URL of an image">
                    </div>
                    <div class="form-group validateButton">
                        <button type="button" class="btn btn-primary my-3" :class="{'disabled': !validSubmit}" @click="addNewNPL">Add NPL</button>
                    </div>
                </div>
            </template>

        </div>
    </div>
</template>

<script>

    export default { 
        name: 'new-npl',
        props: ['parentValue', 'resetNpl'],
        components: {  },
        data() {
            return {
                title: "",
                player: "",
                url: "", 
                doi: "",
                type: -1,
                types: {
                    "merge_and_acquisition": "Merge & Acquisition",
                    "scientific_article": "Scientific article",
                    "investment": "Investissement news",
                    "partnership_and_collaboration": "Partnership & Collaboration",
                    "hiring": "Hiring",
                    "other": "Other"
                },
                inputDate: "",
                patentComment: "",
                imageUrl: "",
                showInput: false,
            }
        },
        computed: {
            npl_to_add(){
                return {
                    "title": this.title,
                    "player": this.player,
                    "url": this.url,
                    "doi": this.doi,
                    "type": this.type,
                    "inputDate": this.inputDate,
                    "patentComment": this.patentComment || "",
                    "IMG": this.imageUrl ? [this.imageUrl] :  []
                }
            },
            validTitle() {
                if (this.title === '') {
                    return false;
                }
                return true;
            },
            validType() {
                if (this.type === -1) {
                    return false;
                }
                return true;
            },
            validDate() {
                const userKeyRegExp = /^\d{4}-(0[1-9]|1[012])-(0[1-9]|[12][0-9]|3[01])$/
                if(this.inputDate === '') {
                    return true;
                }
                if(userKeyRegExp.test(this.inputDate)){
                    return true;
                }
                return false;
            },
            validSubmit() {
                return this.validDate && this.validTitle && this.validType
            }
        },
        methods: {
            resetForm() {
                this.title = ""
            },
            addNewNPL() {
                this.$emit("addNewNPL", this.npl_to_add);
                this.resetForm();
                this.showInput = false;
            },
            toggleInput() {
                this.showInput = !this.showInput
                this.$nextTick(function () {
                    if (this.$refs["title"]) {this.$refs["title"].focus();}
                })
                this.$emit("resetToggle", "nodeNpl");
            }
        },
        watch: {
            resetNpl(){
                if (this.resetNpl === true) {
                    this.resetForm()
                    this.showInput = false;
                }
            }
        }
    }
</script>

<style scoped>

button {
    display: inline-block;
    white-space: nowrap;
}

label {
    text-align: left;
    display: flex;
    margin-left: 10px;
}

small {
    font-size: small;
    display: flex;
    text-align: left;
    font-weight: lighter;
    margin-left: 10px;
}

.validateButton {
    text-align: right;
}
</style>
