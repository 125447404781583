import axios from 'axios';

// const baseURL = 'http://ec2-35-180-32-199.eu-west-3.compute.amazonaws.com:3000/'; 
// const baseURL = 'http://localhost:3000'
const baseURL='https://api.innovation-tree.eu'

const apiService = axios.create({
  baseURL,
  timeout: 5000, // Timeout after 5 seconds
});

export const authService = {
  async register(credentials) {
    return apiService.post('/auth/signup', credentials);
  },
  async confirmRegistration(credentials) {
    return apiService.post('/auth/signupConfirm', credentials);
  },
  async login(credentials) {
    return apiService.post('/auth/signin', credentials);
  },
};

export const updateProject = async (data) => {
  try {
      const response = await apiService.put('/api/projects', data); 
      return response.data; // Return the response data from the server
  } catch (error) {
      console.log(error); // Throw the error to handle it in the calling code
  }
};

export const deleteProject = async (data) => {
  try {
      const response = await apiService.delete('/api/projects',  { data: data }); 
      return response.data; // Return the response data from the server
  } catch (error) {
      console.log(error); // Throw the error to handle it in the calling code
  }
};


export const shareProject = async (data) => {
  try {
      const response = await apiService.post('/api/projects/share', data); 
      return response.data; // Return the response data from the server
  } catch (error) {
      console.log(error); // Throw the error to handle it in the calling code
  }
};

export const listProjects = async (data) => {
  try {
      // Make a GET request to the backend API endpoint to list projects
      const response = await apiService.get('/api/projects/', { params: data });
      // Return the response data
      return response.data;
  } catch (error) {
      // If there's an error, throw it so it can be caught by the caller
      console.log(error);
  }

}

