<template>
    <div>
        <div class="row m-3">
            <div class="col-9">
                <div class="row">
                    <h3>Value chain and R&D efforts of the players</h3>
                    <svg ref="svg"></svg>
                </div>
            </div>
            <div class="col-3 right-column">
                <h3>Applicant options</h3>
                <choose-applicant-options
                    :applicantOptions="applicantOptions"
                    :total_applicants="total_applicants"
                    @newApplicantOption="changeApplicantOption"
                    @newSelectedApplicants="changeSelectedApplicants"
                >
                </choose-applicant-options>

                <div v-if="showPlayer">
                    <h3>Player's portfolio</h3>
                    <h4>{{ this.clicked_player.name }}</h4>
                    <table class="clickedPlayer">
                        <tr>
                            <td><b>Publication</b></td>
                            <td><b>{{ clicked_player.name === 'Other' ? 'Applicant' : 'Node' }}</b></td>
                        </tr>
                        <tr v-for="patent in this.clicked_player.list_patents" :key="get_patent_key(patent)">
                            <td 
                                @click="clickOnPatent(patent)"
                                class="link"
                                >{{ get_patent_name(patent) }}</td>
                            <td v-html="clicked_player.name === 'Other' ? (patent.NPA ? patent.NPA: patent.player) : patent.whereAmI"></td>
                        </tr>
                    </table>
                </div>
                <div v-else-if="showNode" >
                    <h3>Node's list of patents</h3>
                    <h4>
                        {{ this.clicked_node.name }}
                        <!-- <span v-if="reduceListing" role="button" @click="this.reduceListing=false"  style="font-weight: bolder;">
                            <i class="ri-add-box-line"></i>
                        </span> -->
                    </h4>
                    <table class="clickedPlayer"> <!-- v-if="!reduceListing" -->
                        <tr>
                            <td><b>Publication</b></td>
                            <td><b>Applicant</b></td>
                        </tr>
                        <tr v-for="patent in this.clicked_node.list_patents" :key="get_patent_key(patent)">
                            <td 
                                @click="clickOnPatent(patent)"
                                class="link"
                                >{{ get_patent_name(patent) }}</td>
                            <td v-html="patent.NPA"></td>
                        </tr>
                    </table>
                </div>
                <div ref="scrollTarget">
                    <div v-if="showPatent">
                        <h3>Card data</h3>
                        <show-patent 
                            :patent="clicked_patent"
                            :viewMode="'Sankey'"
                            :index="clicked_patent.patent_index"
                            @newComment="updateComment"
                            @newCriticity="updateCriticity"
                            @newIncludeInTimeline="updateIncludeInTimeline"
                        >
                        </show-patent>
                    </div>
                </div>
            </div>
        </div>
        <div class="tooltip"></div> 
    </div>
</template>
  
<script>
    import * as d3 from 'd3';
    import { sankey, sankeyLinkHorizontal } from 'd3-sankey';
    import ChooseApplicantOptions from '@/components/ChooseApplicantOptions.vue'
    import ShowPatent from '@/components/ShowPatent.vue'
    import { 
        getPatentListingFromNode,
        getTotalApplicants,
        getApplicantsFromPatent,
        transform_node_to_sankey_data,
        findNodeInTree,
        changePatentPropertyInNode,
        // getNumberOfPatentForNode
    } from '@/functions/treeutils.js';
    
    export default { 
        props: ['tree'],
        data() {
            return {
                showPlayer: false,
                showNode: false,
                showPatent: false,
                // reduceListing: false,
                clicked_player: {},
                clicked_node: {},
                clicked_patent: {},
                clicked_index: 0,
                // nodeAlign: 'justify',
                linkColor: 'source-target', // source, target or source-target
                applicantOptions: '', // top10, top5 or selectedListing
                isApplicantListingEdited: false,
                selected_applicants: [],
                npl_types: {
                    "merge_and_acquisition": "Merge & Acquisition",
                    "scientific_article": "Scientific article",
                    "investment": "Investissement news",
                    "partnership_and_collaboration": "Partnership & Collaboration",
                    "hiring": "Hiring",
                    "other": "Other"
                },

            }
        },
        name: 'sankey-chart',
        components: { ChooseApplicantOptions, ShowPatent },
        computed: {
            items() {
                return transform_node_to_sankey_data(this.tree, this.selected_applicants)
            },
            total_applicants() {
                return getTotalApplicants(this.tree).sort((a, b) => b.value-a.value) || []
            },
        },
        mounted() {
            this.reRenderSankey()
            this.applicantOptions = 'top10'
        },
        watch: {
            tree() {
                this.applicantOptions = 'top10'
                // // recalculate selected applicants
                // this.selected_applicants = getTotalApplicants(this.tree).sort((a, b) => b.value-a.value).slice(0,10).map((e) => {
                //     return e.applicant
                // })
                this.reRenderSankey();
            },
        },
        methods: {
            get_patent_key(patent){
                if ("ID" in patent) {
                    return patent.ID
                } else if ("title" in patent) {
                    return patent.title
                } else {
                    return patent.order
                }
            },
            get_patent_name(patent){
                if("ID" in patent) {
                    return patent.ID.split("_")[0]
                } else {
                    return patent.title
                }
                
            },
            updateComment(index, newComment){
                let whereAmI = this.clicked_patent.whereAmI
                let newTree = changePatentPropertyInNode(this.tree, whereAmI, index, 'patentComment', newComment)
                this.$emit('update-tree', newTree)
            },
            updateCriticity(index, newCriticity){
                let whereAmI = this.clicked_patent.whereAmI
                let newTree = changePatentPropertyInNode(this.tree, whereAmI, index, 'patentCriticity', newCriticity)
                this.$emit('update-tree', newTree)
            },
            updateIncludeInTimeline(index, newIncludeInTimeline){
                let whereAmI = this.clicked_patent.whereAmI
                let newTree = changePatentPropertyInNode(this.tree, whereAmI, index, 'includeInTimeline', newIncludeInTimeline)
                this.$emit('update-tree', newTree)
            }, 
            scrollToTarget() {
                const el = this.$refs.scrollTarget;

                if (el) {
                    // Use el.scrollIntoView() to instantly scroll to the element
                    el.scrollIntoView({behavior: 'smooth'});
                }
            },

            clickOnPatent(patent){
                this.showPatent=true;
                this.clicked_patent=patent;
                // this.reduceListing=true;
                this.scrollToTarget()
            },
            getPatentListingFromApplicant(node, applicant_name){
                let list_patents = []
                if (node.patents) {
                    let modified_patent_listing = node.patents.map((element, index) => {
                        element['patent_index'] = index
                        return element
                    })
                    list_patents = modified_patent_listing.filter((element) => {
                        let applicants = getApplicantsFromPatent(element)
                        if(applicant_name === 'Other'){
                            for(let i=0; i<applicants.length; i++){
                                let applicant_item = applicants[i]
                                if(!(this.selected_applicants.includes(applicant_item))){
                                    return true
                                }
                            }
                        } else if (applicants.includes(applicant_name)){
                            return true
                        }

                        return false
                    })
                }

                if (node.nodes){
                    for (let i=0; i < node.nodes.length; i ++){
                        list_patents = [...list_patents, ...this.getPatentListingFromApplicant(node.nodes[i], applicant_name)]
                    }
                }
                return list_patents
            },
            changeApplicantOption(value){
                this.applicantOptions = value;
            },
            changeSelectedApplicants(value){
                this.selected_applicants = value;
                this.reRenderSankey();
            },
            reRenderSankey() {
                const svg = d3.select("svg");
                svg.selectAll("*").remove();
                this.showPlayer=false;
                this.showNode=false;
                this.showPatent=false;

                this.renderSankey()
            },
            buildListPatentsFromApplicants(name){
                let list_patents = []
                list_patents = this.getPatentListingFromApplicant(this.tree, name)
                this.clicked_player = {"name": name, "list_patents": list_patents}
                this.clicked_node = {}
            },
            buildListPatentsFromNode(node_name){
                let list_patents = []
                let node = findNodeInTree(this.tree, node_name)
                list_patents = getPatentListingFromNode(node)
                this.clicked_player = {}
                this.clicked_node = {"name": node_name, "list_patents": list_patents}
            },
            renderSankey() {
                const width = 1000;
                const height = 200 + this.items?.nodes?.length * 20
                // const marginTop = 50;
                // const marginRight = 40;
                // const marginBottom = 50;
                // const marginLeft = 120;
                const format = d3.format(",.1f");

                // Create a SVG container.
                const svg = d3
                    .select("svg")
                    .attr("width", width)
                    .attr("height", height)
                    .attr("viewBox", [0, 0, width, height])
                    // .attr("viewBox", [-marginLeft, -marginTop, width, height])
                    .attr("style", "max-width: 100%; height: auto; background-color: white; font: 18px sans-serif;");

                // Constructs and configures a Sankey generator.
                const s = sankey()
                    .nodeId(d => d.name)
                    // .nodeAlign(d3.sankeyLeft) // d3.sankeyLeft, etc.
                    .nodeWidth(15)
                    .nodePadding(10)
                    .extent([[1, 5], [width - 1, height - 5]]);

                // Applies it to the data. We make a copy of the nodes and links objects
                // so as to avoid mutating the original.
                const {nodes, links} = s({
                    nodes: this.items.nodes.map(d => Object.assign({}, d)).filter(d => d?.value > 0 || d.name === 'Project root'),
                    links: this.items.links.map(d => Object.assign({}, d)).filter(d => d?.value > 0)
                });

                

                // Defines a color scale.
                const color = d3.scaleOrdinal(d3.schemeCategory10);
                const mouseclick = (event, d) => {
                    if ("name" in d) {
                        // if clicked rect is an applicant
                        if (this.selected_applicants.includes(d.name) || d.name === 'Other'){
                            this.buildListPatentsFromApplicants(d.name)
                            this.showPlayer = true;
                            this.showNode = false;
                            this.showPatent = false
                        } 
                        // if clicked rect is a node
                        else {
                            this.buildListPatentsFromNode(d.name);
                            this.showNode = true
                            this.showPlayer = false;
                            this.showPatent = false;
                        }

                    }
                };

                var tooltip = d3.select(".tooltip")
                    .style("opacity", 0);

                /* eslint-disable-next-line */
                const mouseover = (event, d) => {
                    tooltip.style("opacity", 1);
                };

                /* eslint-disable-next-line */
                const mouseleave = (event, d) => {
                    tooltip.style('opacity', 0);
                }

                const mousemove = (event, d) => {
                    tooltip.style("background-color", "white")
                        .style("border", "2px solid black")
                        .style("padding", "8px")
                        .style("position", "absolute")
                        .style("top", event.pageY + 10 + "px")
                        .style("left", event.pageX + 10 + "px")
                        .style("opacity", 1)
                        .style("max-width", "600px")

                    if('name' in d) {
                        var tooltip_text = ""// `${d.name}<br/><b>${format(d.value)} occurences (ponderated by co-occurences)</b><br/>`
                        tooltip_text += `<b>${d.patent_number ? d.patent_number : 0} patent application(s)</b><br/>`
                        for(const npl_type in this.npl_types){
                            if(npl_type in d && d[npl_type] != 0){
                                tooltip_text += `<b>${d[npl_type]} ${this.npl_types[npl_type]}</b><br/>`
                            }
                        }
                        tooltip.html(tooltip_text);
                    }
                    else if ('source' in d){
                        tooltip.html(`${d.source.name} → ${d.target.name}<br/><b>${format(d.value)} ponderated occurences (patent/npl by coownership)<b>`);
                    }
                };

                // Creates the rects that represent the nodes.
                /* eslint-disable-next-line */
                const rect = svg.append("g")
                    .attr("stroke", "#000")
                    .selectAll()
                    .data(nodes)
                    .join("rect")
                    .attr("x", d => d.x0)
                    .attr("y", d => d.y0)
                    .attr("height", d => d.y1 - d.y0)
                    .attr("width", d => d.x1 - d.x0)
                    .attr("fill", d => color(d.id))
                    .on("click", mouseclick)
                    .on("mousemove", mousemove)
                    .on("mouseleave", mouseleave)
                    .on("mouseover", mouseover);

                // Creates the paths that represent the links.
                const link = svg.append("g")
                    .attr("fill", "none")
                    .attr("stroke-opacity", 0.5)
                    .selectAll()
                    .data(links)
                    .join("g")
                    .style("mix-blend-mode", "multiply");

                // Creates a gradient, if necessary, for the source-target color option.
                if (this.linkColor === "source-target") {
                    const gradient = link
                        .append("linearGradient")
                        .attr("id", d => (d.uid = `${d.source.id}-to-${d.target.id}`))
                        .attr("gradientUnits", "userSpaceOnUse")
                        .attr("x1", d => d.source.x1)
                        .attr("x2", d => d.target.x0);
                    gradient
                        .append("stop")
                        .attr("offset", "0%")
                        .attr("stop-color", d => color(d.source.id));
                    gradient
                        .append("stop")
                        .attr("offset", "100%")
                        .attr("stop-color", d => color(d.target.id));
                }


                link.append("path")
                    .attr("d", sankeyLinkHorizontal())
                    .attr("stroke", this.linkColor === "source-target" ? (d) => `url(#${d.uid})`
                        : this.linkColor === "source" ? (d) => color(d.source.id)
                        : this.linkColor === "target" ? (d) => color(d.target.id) 
                        : this.linkColor)
                    .attr("stroke-width", d => Math.max(1, d.width))
                    .on("mousemove", mousemove)
                    .on("mouseleave", mouseleave)
                    .on("mouseover", mouseover);

                // Adds labels on the nodes.
                svg.append("g")
                    .selectAll()
                    .data(nodes)
                    .join("text")
                    .attr("x", d => d.x0 < width / 2 ? d.x1 + 6 : d.x0 - 6)
                    .attr("y", d => (d.y1 + d.y0) / 2)
                    .attr("dy", "0.35em")
                    .attr("text-anchor", d => d.x0 < width / 2 ? "start" : "end")
                    .text(d => {
                        let name = d.name
                        if (name.length > 0) {
                            name = name.charAt(0).toUpperCase() + name.slice(1).toLowerCase();
                        }
                        if (name.length > 20){
                            name = name.slice(0,20) + '...'
                        }
                        return name
                    });

                return svg.node();
            }
        }
    }
</script>
  
<style scoped>

h2 {
    margin-top: 30px;
    margin-bottom : 30px
}
h3 {
    margin-top: 20px;
}

svg {
    padding: 10px;
    border: solid lightblue;
    margin-bottom: 30px;
    padding-inline: max(2rem, calc(50% - 24rem));
}

.link {
    color: blue;
    text-decoration: underline;
    cursor: pointer;
}
.radioselect {
    padding: 10px;
    margin-left: 30px;
    margin: 5px;
    margin-bottom: 10px;
    text-align: left;
}

table tr td {
    border: solid black 1px;
    text-align: center;
    padding: 5px;
    padding-left: 10px;
    padding-right: 10px;
}

#editApplicantListing {
    margin-left: 10px;
}

.right-column {
    border: 1px solid lightgrey;
    max-height: 80vh;          /* not available in Bootstrap */
    overflow-y: scroll;
}

</style>