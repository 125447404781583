<template>
    <div>
        <label for="height" class="form-label">Height: <b>{{ this.myHeight }}</b></label>
        <input type="range" class="form-range" min="100" max="500" step="20" id="height" v-model="myHeight">

        <label for="tickHeight" class="form-label">Tick height: <b>{{ this.myTickHeight }}</b></label>
        <input type="range" class="form-range" min="10" max="50" step="5" id="tickHeight" v-model="myTickHeight">

        <label for="fontSizeInt" class="form-label">Font size: <b>{{ this.myFontSizeInt }}</b></label>
        <input type="range" class="form-range" min="8" max="24" step="2" id="fontSizeInt" v-model="myFontSizeInt">

        <label for="lineLength" class="form-label">Line length: <b>{{ this.myLineLength }}</b></label>
        <input type="range" class="form-range" min="10" max="30" step="1" id="lineLength" v-model="myLineLength">

        <label for="maxLength" class="form-label">Max length: <b>{{ this.myMaxLength }}</b></label>
        <input type="range" class="form-range" min="30" max="200" step="10" id="maxLength" v-model="myMaxLength">

        <label for="sideMargins" class="form-label">Side margins: <b>{{ this.mySideMargins }}</b></label>
        <input type="range" class="form-range" min="10" max="120" step="10" id="sideMargins" v-model="mySideMargins">

    </div>
</template>

<script>

    export default { 
        name: 'choose-timeline-options',
        props: ['timelineOptions'],
        data() {
            return {
                myHeight: this.timelineOptions["height"] || 400,
                myTickHeight: this.timelineOptions["tickHeight"] || 15,
                myFontSizeInt: this.timelineOptions["fontSizeInt"] || 16,
                myLineLength: this.timelineOptions["lineLength"] || 15,
                myMaxLength: this.timelineOptions["maxLength"] || 100,
                mySideMargins: this.timelineOptions["sideMargins"] || 30,
            }
        },
        methods: {
            // Emitting new applicant option to parent component
            emitTimelineOption() {
                this.$emit('newTimelineOption', this.myTimelineOptions)
            }
        },
        computed: {
            myTimelineOptions() {
                return {
                    "height": this.myHeight,
                    "tickHeight": this.myTickHeight,
                    "fontSizeInt": this.myFontSizeInt,
                    "lineLength": this.myLineLength,
                    "maxLength": this.myMaxLength,
                    "sideMargins": this.mySideMargins
                }
            }
        },
        watch: {
            timelineOptions(){
                this.myHeight = this.timelineOptions["height"]
                this.myTickHeight = this.timelineOptions["tickHeight"]
                this.myFontSizeInt = this.timelineOptions["fontSizeInt"]
                this.myLineLength = this.timelineOptions["lineLength"]
                this.myMaxLength = this.timelineOptions["maxLength"]
                this.mySideMargins = this.timelineOptions["sideMargins"]
            },
            myHeight() {
                this.emitTimelineOption()
            },
            myTickHeight() {
                this.emitTimelineOption() 
            },
            myFontSizeInt() {
                this.emitTimelineOption()
            },
            myLineLength() {
                this.emitTimelineOption()
            },
            mySideMargins() {
                this.emitTimelineOption()
            },
            myMaxLength() {
                this.emitTimelineOption()
            }
        }
    }
</script>



<style scoped>
</style>
