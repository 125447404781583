// store/index.js
import { createStore } from 'vuex';
import { authService } from '@/services/apiService.js'; // Import your authService
import router from '@/router'

export default createStore({
    state: {
        isAuthenticated: localStorage.isAuthenticated || false,
        email: localStorage.email || null,
        token: localStorage.token || null,
		userId: localStorage.userId || null,
    },
    mutations: {
		login(state, { userId, token, email }) {
			state.isAuthenticated = true;
			state.email = email;
			state.userId = userId;
			state.token = token;

			// Save token to localStorage for persistent login state
			localStorage.setItem('isAuthenticated', true);
			localStorage.setItem('userId', userId);
			localStorage.setItem('token', token);
			localStorage.setItem('email', email);
		},
		logout(state) {
			state.isAuthenticated = false;
			state.email = null;
			state.token = null;
			state.userId = null;
			// Remove token from localStorage
			localStorage.removeItem('isAuthenticated');
			localStorage.removeItem('token');
			localStorage.removeItem('email');
			localStorage.removeItem('userId');
		}
	},
	actions: {
		async login({ commit }, credentials) {
			try {
				// Call the login method of authService
				const returnData = await authService.login(credentials);
				const user = returnData.data.user
				const idToken = user.idToken;
				const email = credentials.email;
				// Split the idToken string by '.' to extract the payload part
				const parts = idToken.split('.');
				const encodedPayload = parts[1];

				// Decode the base64-encoded payload
				const decodedPayload = atob(encodedPayload);

				// Parse the JSON string to get the token payload as an object
				const tokenPayload = JSON.parse(decodedPayload);

				// Extract the user ID from the token payload
				const userId = tokenPayload.sub; // Assuming the user ID is stored under the 'sub' field

				const accessToken = user.accessToken ? user.accessToken : "";
				// Commit the login mutation to update the state
				commit('login', { userId, token: accessToken, email });
				// Return user data or any other necessary information
				return user;
			} catch (error) {
				// Handle login error
				console.error('Error logging in:', error);
				throw new Error('Login failed');
			}
		},
		async register({ commit }, credentials) {
			try {
				// Call your authentication service's register method
				await authService.register(credentials);
				console.log('test', commit)
				// Optionally, you can commit a mutation to update state if needed
			} catch (error) {
				// Handle registration error
				console.log(error); 
			}
		},
		async confirmRegistration({ commit }, credentials) {
			try {
				// Call your authentication service's confirmRegistration method
				await authService.confirmRegistration(credentials);
				console.log('test', commit)
				// Optionally, you can commit a mutation to update state if needed
			} catch (error) {
				// Handle confirmation error
				console.log(error); 
			}
		},
		logout({ commit }) {
			commit('logout');
			router.push('/login');
		}
	},
	getters: {
		// Getter to check if user is authenticated
		isAuthenticated: state => state.isAuthenticated
	}

});
