<template>
   <div :style="{ 
        'width': '100%', 
        'height': '47px',
        'background': 'white',
        'line-height': '47px',
        'border-bottom': '1px solid #e6e6e6',
        'display': 'flex',
        'justify-content': 'space-between'
        }"
    >
        <div id='left'>
            <button class='button' :style="{'padding': '0px 10px'}"></button> 
            <button id='Edit' @click="changeViewNode('Edit')" :class="{ active: viewMode === 'Edit' }" class="button" :style="{'padding': '0px 20px', 'border-right': '1px solid lightgrey'}" v-tooltip title="Build project"><i class="ri-edit-box-line"></i></button> 
            <button id='View' @click="changeViewNode('View')" :class="{ active: viewMode === 'View' }" class="button" :style="{'padding': '0px 20px', 'border-right': '1px solid lightgrey'}" v-tooltip title="View project"><i class="ri-file-list-line"></i></button> 
            <button id='Play' @click="changeViewNode('Play')" :class="{ active: viewMode === 'Play' }" class='button' :style="{'padding': '0px 20px', 'border-right': '1px solid lightgrey'}" v-tooltip title="Explore"><i class="ri-mind-map"></i></button> 
            <button id='Sankey' @click="changeViewNode('Sankey')" :class="{ active: viewMode === 'Sankey' }" class='button' :style="{'padding': '0px 20px', 'border-right': '1px solid lightgrey'}" v-tooltip title="Value chain"><i class="ri-layout-masonry-line"></i></button> 
            <button id='Force' @click="changeViewNode('Force')" :class="{ active: viewMode === 'Force' }" class='button' :style="{'padding': '0px 20px', 'border-right': '1px solid lightgrey'}" v-tooltip title="Relationship between actors"><i class="ri-bubble-chart-line"></i></button> 
            <button id='Time' @click="changeViewNode('Time')" :class="{ active: viewMode === 'Time' }" class='button' :style="{'padding': '0px 20px', 'border-right': '1px solid lightgrey'}" v-tooltip title="Timeline"><i class="ri-history-fill"></i></button> 
            <button @click="launchTour()" class="button" :style="{'padding': '0px 20px', 'border-right': '1px solid lightgrey'}" v-tooltip title="Start tour"><i class="ri-question-mark"></i></button> 
        </div>
        <div id='right'>
            <export-save-load
                :tree="this.tree"
                @load-tree="loadTree"
            />
        </div>

    </div>
    <!-- <div class="bar">
        <button class='button button-big' :style="{'padding': '0px 20px'}"><i class="ri-circle-line"></i></button> 
        <button class='button button-big' :style="{'padding': '0px 20px'}"><i class="ri-square-line"></i></button> 
        <button class='button button-big' :style="{'padding': '0px 20px', 'border-right': '1px solid lightgrey'}"><i class="ri-article-line"></i></button> 
        <button class='button button-big' :style="{'padding': '0px 20px', 'border-right': '1px solid lightgrey'}"><i class="ri-text"></i></button> 
        <button class='button button-big' :style="{'padding': '0px 20px'}"><i class="ri-delete-bin-line"></i></button> 
    </div> -->
    <div v-if="['Play'].includes(viewMode)">
        <tree-chart
            :tree="tree"
            @newSwot="updateSwot"
            @update-tree="updateTree"
        ></tree-chart>
    </div>

    <div v-if="['Sankey'].includes(viewMode)">
        <sankey-chart
            v-if="typeof tree !== 'undefined'"
            :tree="tree"
            @update-tree="updateTree"
        ></sankey-chart>
    </div>

    <div v-if="['Force'].includes(viewMode)">
        <force-chart
            v-if="typeof tree !== 'undefined'"
            :tree="tree"
            @update-tree="updateTree"
        ></force-chart>
    </div>
    <tree-menu 
        v-if="['Edit', 'View'].includes(viewMode)"
        :label="tree.label" 
        :nodes="tree.nodes" 
        :patents="tree.patents" 
        :depth=0 
        :whereAmI="tree.label"
        :numberOfParentNodes="tree.nodes.length"
        @update-tree="updateTree"
        @change-node-order="changeNodeOrder"
        :viewMode="viewMode"
    ></tree-menu>

    <time-line
        v-if="['Time'].includes(viewMode)"
        :tree="tree"
    >

    </time-line>
</template>

<script>
    import TreeMenu from '../components/TreeMenu.vue'
    // import ChooseViewMode from './components/ChooseViewMode.vue'
    import TreeChart from '../components/TreeChart.vue'
    import SankeyChart from '../components/SankeyChart.vue'
    import ExportSaveLoad from '../components/ExportSaveLoad.vue'
    import ForceChart from '../components/ForceChart.vue'
    import TimeLine from '../components/TimeLine.vue'

    let empty_tree = {
        label: 'Project root',
        nodes: [],
        patents: [],
        strength: '',
        weakness: '',
        opportunity: '',
        threat: ''
    }

    export default {
        data() {
            return {
                tour: null,
                tree: empty_tree,
                treeJSON: '',
                showInput: false,
                newTreeValue: '',
                viewMode: 'Edit'
            }
        },
        components: {
            TreeMenu,
        //  ChooseViewMode,
            TreeChart,
            SankeyChart,
            ExportSaveLoad,
            ForceChart,
            TimeLine
        },
        methods: {
            changeViewNode(value) {
                this.viewMode = value
            },
            updateTree(changedTree) {
                this.tree = changedTree
            },
            loadTree(loadedTree) {
                this.tree = loadedTree
            },
            changeNodeOrder(old_index, new_index) {
                let tmp = this.tree.nodes.splice(old_index, 1)
                this.tree.nodes.splice(new_index, 0, tmp[0]);
            },
            updateSwot(newStrength, newWeakness, newOpportunity, newThreat){
                this.tree.strength = newStrength;
                this.tree.weakness = newWeakness;
                this.tree.opportunity = newOpportunity;
                this.tree.threat = newThreat;
            },
            createTour(){
                this.tour = this.$shepherd({
                    defaultStepOptions: {
                        cancelIcon: {
                            enabled: true
                        },

                        // classes: 'shadow-md bg-purple-dark',
                        scrollTo: {
                            behavior: 'smooth',
                            block: 'center'
                        }
                    },
                    useModalOverlay: true
                });

                this.tour.addStep({
                    title: 'Build your tree',
                    text: 'Add nodes, add patent and add NPLs that are relevant to your project.',
                    attachTo: {
                        element: '#Edit',
                        on: 'bottom'
                    },
                    buttons: [
                        {
                            text: 'Next',
                            classes: 'btn btn-success',
                            action: this.tour.next
                        }
                    ]
                });
                this.tour.addStep({
                    title: 'View your tree',
                    text: 'Explore all patents and NPLs inside the various nodes that you have built. Note their criticity and add comments.',
                    attachTo: {
                        element: '#View',
                        on: 'bottom'
                    },
                    buttons: [
                        {
                            text: 'Back',
                            classes: 'btn btn-light',
                            action: this.tour.back
                        },
                        {
                            text: 'Next',
                            classes: 'btn btn-success',
                            action: this.tour.next
                        }
                    ]
                });
                this.tour.addStep({
                    title: 'Explore your tree',
                    text: 'Explore your tree with autocollapsible features. Edit and build your SWOT analysis.',
                    attachTo: {
                        element: '#Play',
                        on: 'bottom'
                    },
                    buttons: [
                        {
                            text: 'Back',
                            classes: 'btn btn-light',
                            action: this.tour.back
                        },
                        {
                            text: 'Next',
                            classes: 'btn btn-success',
                            action: this.tour.next
                        }
                    ]
                });
                this.tour.addStep({
                    title: 'Sankey diagram',
                    text: 'Build your value chain and R&D efforts ',
                    attachTo: {
                        element: '#Sankey',
                        on: 'bottom'
                    },
                    buttons: [
                        {
                            text: 'Back',
                            classes: 'btn btn-light',
                            action: this.tour.back
                        },
                        {
                            text: 'Next',
                            classes: 'btn btn-success',
                            action: this.tour.next
                        }
                    ]
                });
                this.tour.addStep({
                    title: 'Force diagram',
                    text: 'Explore the relationship between all the co-players and co-applicants',
                    attachTo: {
                        element: '#Force',
                        on: 'bottom'
                    },
                    buttons: [
                        {
                            text: 'Back',
                            classes: 'btn btn-info',
                            action: this.tour.back
                        },
                        {
                            text: 'Next',
                            classes: 'btn btn-success',
                            action: this.tour.next
                        }
                    ]
                });
                this.tour.addStep({
                    title: 'Timeline',
                    text: 'Position selected patents and NPL events on a timeline to understand specific relations between them',
                    attachTo: {
                        element: '#Time',
                        on: 'bottom'
                    },
                    buttons: [
                        {
                            text: 'Back',
                            classes: 'btn btn-light',
                            action: this.tour.back
                        },
                        {
                            text: 'Thank you !',
                            classes: 'btn btn-primary',
                            action: this.tour.complete
                        }
                    ]
                });

            },
            launchTour() {
                this.createTour();
                this.tour.start();
            }

        }
    }
</script>

<style>
    .button {
        border: 0;
        background: transparent;
    }
    .button-big {
        font-size: 40px;
        color: grey
    }
    .active {
        background-color: orangered;
        color: white;
    }
    .bar {
        z-index: 100;
        box-shadow: 0px 0px 5px 0px lightgrey;
        position: fixed;
        background: white;
        border: 1px solid #e6e6e6;
        border-radius: 15px;
        bottom: 20px;
        text-align: center;
        margin-left: calc(50% - 210px); 
        width: 420px;
        height: 80px;
        line-height: 80px
    }
    #app {
    font-family: Avenir, Helvetica, Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    color: #2c3e50;
    margin-top: 0px;
    }
</style>
