<template>
    <div>
        <div class="row m-3">
            <div class="col-9">
                <div class="row">
                    <h3>Milestones</h3>
                    <div ref="svg" id="svg" style="background-color:white"></div>
                </div>
            </div>
            <div class="col-3 right-column">
                <h3>Graph options</h3>
                <choose-timeline-options
                    :timelineOptions="timelineOptions"
                    @newTimelineOption="changeTimelineOption"
                />

                <div v-if="showPlayer">
                    <h3>Player's portfolio</h3>
                    <h4>{{ this.clicked_player.name }}</h4>
                    <table class="clickedPlayer">
                        <tr>
                            <td><b>Publication</b></td>
                            <td><b>{{ clicked_player.name === 'Other' ? 'Applicant' : 'Node' }}</b></td>
                        </tr>
                        <tr v-for="patent in this.clicked_player.list_patents" :key="get_patent_key(patent)">
                            <td 
                                @click="clickOnPatent(patent)"
                                class="link"
                                >{{ get_patent_name(patent) }}</td>
                            <td v-html="clicked_player.name === 'Other' ? (patent.NPA ? patent.NPA: patent.player) : patent.whereAmI"></td>
                        </tr>
                    </table>
                </div>
                <div ref="scrollTarget">
                    <div v-if="showPatent">
                        <h3>Card data</h3>
                        <show-patent 
                            :patent="clicked_patent"
                            :viewMode="'Sankey'"
                            :index="clicked_patent.patent_index"
                            @newComment="updateComment"
                            @newCriticity="updateCriticity"
                            @newIncludeInTimeline="updateIncludeInTimeline"
                        >
                        </show-patent>
                    </div>
                </div>
            </div>
        </div>
        <div class="tooltip"></div> 
    </div>
</template>
  
<script>
    import * as d3 from 'd3';
    import * as Plot from "@observablehq/plot";

    function wrapText(inputString, segmentLength) {
        inputString = inputString.replaceAll("<br/>"," ")
        const words = inputString.split(" ");
        let result = "";
        let currentLineLength = 0;
        let numberOfLines = 0;

        for (const word of words) {
            if (currentLineLength + word.length + 1 <= segmentLength) {
                // Add the word and a space to the current line
                result += (result === "" ? "" : " ") + word;
                currentLineLength += word.length + 1;
            } else {
                // Start a new line with the word
                result += "\n" + word;
                currentLineLength = word.length;
                numberOfLines++;
            }
        }

        // Count the last line
        if (result !== "") {
            numberOfLines++;
        }

        return {
            text: result,
            numberOfLines: numberOfLines
        };
    }

    function map_function(d, lineLength, maxLength) {
        let title = d.title; 
        if (title.length > maxLength) {
            title = title.substring(0, maxLength) + "..."
        }
        let applicant = d.applicant;
        if (applicant && applicant.length > 15) {
            applicant = "(" + applicant.substring(0,15) + "..." + ")"
        } else if (applicant) {
            applicant = "(" + applicant + ")"
        }
        // const composerShort = d.composer.split(" ").slice(-1);
        const { text, numberOfLines } = wrapText(
            `${title} ${applicant}`,
            lineLength
        );
        d.newDate = new Date(d.date)
        return { ...d, text, numberOfLines };
    }

    // import milestones from 'd3-milestones'
    // stylesheet = html`<link rel='stylesheet' href='https://unpkg.com/d3-milestones@1.0.0-beta1/build/d3-milestones.css' />`

    import ChooseTimelineOptions from '@/components/ChooseTimelineOptions.vue'
    import ShowPatent from '@/components/ShowPatent.vue'
    import { 
        // getTotalApplicants,
        // getApplicantsFromPatent,
        get_data_from_patent,
        changePatentPropertyInNode,
    } from '@/functions/treeutils.js';

    export default { 
        props: ['tree'],
        data() {
            return {
                timelineOptions: {
                    "height":400,
                    "tickHeight":20,
                    "fontSizeInt":16,
                    "lineLength":15,
                    "maxLength": 100,
                    "sideMargins": 30
                },
                npl_types: {
                    "merge_and_acquisition": "Merge & Acquisition",
                    "scientific_article": "Scientific article",
                    "investment": "Investissement news",
                    "partnership_and_collaboration": "Partnership & Collaboration",
                    "hiring": "Hiring",
                    "other": "Other"
                }
                
            }
        },
        name: 'time-line',
        components: { 
             ShowPatent, 
             ChooseTimelineOptions
        },
        mounted() {
            this.renderTimeline()
        },
        computed: {
            lineLength() {
                return (this && this.timelineOptions["lineLength"] || 15)
            },
            filtered_list() {
                return this.getPatentListingFromIncludeInTimeline(this.tree)
            },
            maxLength() {
                return (this && this.timelineOptions["maxLength"] || 100)
            },
            preparedData() {
                return this.filtered_list
                    .map((d) => map_function(d, this.lineLength, this.maxLength))
                    .sort((a, b) => d3.ascending(a.date, b.date))
            },
            fontSizeInt() {
                return this.timelineOptions["fontSizeInt"]
            },
            tickHeight() {
                return this.timelineOptions["tickHeight"]
            },
            sideMargins() {
                return this.timelineOptions["sideMargins"]
            },
            height() {
                return this.timelineOptions["height"]
            },

        },
        watch: {
            tree() {
                this.renderTimeline();
            },
        },
        methods: {
            renderTimeline() {
                const div = document.querySelector("#svg");
                while (div.firstChild) div.removeChild(div.firstChild);
                div.append(this.returnPlot())
            },
            returnPlot() {
                
                return Plot.plot({
                    style: {
                        fontSize: this.fontSizeInt + "px",
                        fontFamily: "Lato"
                    },
                    width: 900,
                    height: this.height,
                    marginLeft: this.sideMargins,
                    marginRight: this.sideMargins,
                    // x: {round: true, nice: d3.utcWeek},
                    x: { axis: null },
                    y: { axis: null, domain: [-this.height / 2, this.height / 2] },
                    marks: [
                        Plot.ruleY([0]),
                        Plot.ruleX(this.preparedData, {
                            x: "newDate",
                            y: (d, i) => (i % 2 === 0 ? this.tickHeight : -this.tickHeight)
                        }),
                        Plot.dot(this.preparedData, { x: "newDate", fill: "#fff", stroke: "#000" }),
                        Plot.text(this.preparedData, {
                        x: "newDate",
                        y: (d, i) => (i % 2 === 0 ? -this.fontSizeInt / 2 - 4 : this.fontSizeInt / 2 + 4),
                        text: (d) => d.newDate.toLocaleDateString()
                        }),
                        Plot.text(this.preparedData, {
                                x: "newDate",
                            y: (d, i) => {
                                
                                return i % 2 === 0
                                ? (1*this.tickHeight + (d.numberOfLines * this.fontSizeInt * 0.5))
                                : (-1*this.tickHeight - (d.numberOfLines * this.fontSizeInt * 0.5))
                            },
                            text: "text"
                        })
                    ]
                })
            },
            getPatentListingFromIncludeInTimeline(node){
                let list_patents = []
                if (node.patents) {
                    let patent_listing = node.patents.map((element, index) => {
                        let return_element = get_data_from_patent(element)
                        return_element['patent_index'] = index
                        return_element.includeInTimeline = element.includeInTimeline
                        return (return_element)
                    })
                    list_patents = patent_listing.filter((element) => {
                        if (element.includeInTimeline){
                            return true
                        }
                        return false
                    })
                }

                if (node.nodes){
                    for (let i=0; i < node.nodes.length; i ++){
                        list_patents = [...list_patents, ...this.getPatentListingFromIncludeInTimeline(node.nodes[i])]
                    }
                }
                return list_patents
            },
            changeTimelineOption(value){
                this.timelineOptions = value;
                this.renderTimeline();
            },
            updateComment(index, newComment){
                let whereAmI = this.clicked_patent.whereAmI
                let newTree = changePatentPropertyInNode(this.tree, whereAmI, index, 'patentComment', newComment)
                this.$emit('update-tree', newTree)
            },
            updateCriticity(index, newCriticity){
                let whereAmI = this.clicked_patent.whereAmI
                let newTree = changePatentPropertyInNode(this.tree, whereAmI, index, 'patentCriticity', newCriticity)
                this.$emit('update-tree', newTree)
            },
            updateIncludeInTimeline(index, newIncludeInTimeline){
                let whereAmI = this.clicked_patent.whereAmI
                let newTree = changePatentPropertyInNode(this.tree, whereAmI, index, 'includeInTimeline', newIncludeInTimeline)
                this.$emit('update-tree', newTree)
            }, 

        },
    }
</script>
  
<style scoped>
@import url('https://fonts.googleapis.com/css2?family=Lato&display=swap');


</style>