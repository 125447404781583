import { createApp } from 'vue'
import App from './App.vue';
import router from '@/router';

// import HomeView from './views/HomeView.vue'
import TreeMenu from './components/TreeMenu.vue'
import VueShepherdPlugin from 'vue-shepherd';
import { tooltip, popover } from './tooltip'

import "./assets/tree.css"
import 'bootstrap'
import 'bootstrap-vue'
import 'bootstrap/dist/css/bootstrap.css'
import 'bootstrap-vue/dist/bootstrap-vue.css'
import 'remixicon/fonts/remixicon.css'
import store from './store'
require('shepherd.js/dist/css/shepherd.css');

const app = createApp(App).use(store)

app.component('tree-menu', TreeMenu)
app.use(VueShepherdPlugin)
app.use(router);
app.directive('tooltip', tooltip)
app.directive('popover', popover)

app.mount('#app')
