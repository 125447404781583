<template>
  <div class="confirm-registration">
    <h2>Confirm Registration</h2>
    <form @submit.prevent="submitConfirmationForm" class="confirm-registration-form">
      <div class="form-group">
        <label for="email">Email:</label>
        <input type="email" id="email" v-model="email" required>
      </div>
      <div class="form-group">
        <label for="confirmationCode">Confirmation Code:</label>
        <input type="text" id="confirmationCode" v-model="confirmationCode" required>
      </div>
      <button type="submit">Confirm</button>
    </form>
    <p>Back to <router-link to="/login">Login</router-link></p>
  </div>
</template>

<script>
import { mapActions } from 'vuex';
// import { authService } from '@/services/apiService'; 

export default {
    data() {
        return {
            email: '',
            confirmationCode: ''
        };
    },
    methods: {
		...mapActions(['confirmRegistration']),
		async submitConfirmationForm() {
			try {
				await this.confirmRegistration({email: this.email, code: this.confirmationCode});
				this.$router.push('/login');
			} catch (error) {
				console.error('Confirmation failed:', error);
			}
		}
        // async confirmRegistration() {
        //     try {
        //         const response = await authService.signupConfirm({
        //             email: this.email,
        //             code: this.code
        //         });
        //         // Check if confirmation was successful
        //         if (response.data.success) {
        //             // Redirect user to login page or another page
        //             this.$router.push('/login');
        //         } else {
        //             // Display error message to user
        //             console.error(response.data.message);
        //         }
        //     } catch (error) {
        //         console.error('Error confirming registration:', error);
        //     }
        // }
    }
};
</script>

<style scoped>
.confirm-registration {
  display: flex;
  flex-direction: column;
  align-items: center; /* Vertically align items */
  max-width: 400px;
  margin: 0 auto;
  margin-top: 200px; /* Adjust margin as needed */
}
.form-group {
  display: flex;
  flex-direction: column;
  width: 300px; 
}
.confirm-registration form {
  display: flex;
  flex-direction: column;
}
.confirm-registration form div {
  margin-bottom: 10px;
}
.confirm-registration form label {
  font-weight: bold;
}
.confirm-registration form input {
  padding: 5px;
  font-size: 1rem;
}
.confirm-registration form button {
  padding: 8px 15px;
  background-color: #007bff;
  color: #fff;
  border: none;
  cursor: pointer;
}
.confirm-registration form button:hover {
  background-color: #0056b3;
}
p {
  margin-top: 15px;
}
</style>
