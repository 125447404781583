<template>
    <div id="app">
      <router-view/>
    </div>
  </template>

  <script>
  export default {
    name: 'App',
    // Other options or methods can be defined here if needed
  }
  </script>
  
  <style>
  /* Global styles can be defined here */
  body {
    font-family: Arial, sans-serif;
  }
  </style>
  