<template>
    <div>
        <label for="arrowLength" class="form-label">Length of link: <b>{{ this.myArrowLength }}</b></label>
        <input type="range" class="form-range" min="50" max="200" step="25" id="arrowLength" v-model="myArrowLength">

        <label for="textSize" class="form-label">Size of text: <b>{{ this.myTextSize }}</b></label>
        <input type="range" class="form-range" min="12" max="24" step="1" id="textSize" v-model="myTextSize">

    </div>
</template>

<script>

    export default { 
        name: 'choose-force-graph-options',
        props: ['forceGraphOptions'],
        data() {
            return {
                myArrowLength: this.forceGraphOptions["arrowLength"] || 50,
                myTextSize: this.forceGraphOptions["textSize"] || 12
            }
        },
        methods: {
            // Emitting new applicant option to parent component
            emitForceGraphOption() {
                this.$emit('newForceGraphOption', this.myForceGraphOptions)
            }
        },
        computed: {
            myForceGraphOptions() {
                return {
                    "arrowLength": this.myArrowLength,
                    "textSize": this.myTextSize
                }
            }
        },
        watch: {
            forceGraphOptions(){
                this.myArrowLength = this.forceGraphOptions["arrowLength"]
                this.myTextSize = this.forceGraphOptions["textSize"]
            },
            myArrowLength() {
                this.emitForceGraphOption()
            },
            myTextSize() {
                this.emitForceGraphOption() 
            }
        }
    }
</script>



<style scoped>
</style>
