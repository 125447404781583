<template>
     <div> 
        <div class="form-group row">
            <div class="col-md-1" @click="toggleInput">
                <button 
                    class="btn btn-sm btn-light rounded-pill"
                    v-tooltip
                    :title="'Add a patent to ' + parentValue"
                >
                    <span><i class="ri-file-paper-2-line mx-2"></i></span>
                    <span class="badge rounded-pill bg-info" style="margin-left: -15px; margin-right: 10px; font-size: 5px">+</span>
                    <span>Add patent</span>
                </button>
            </div>
            <template v-if="showInput">
                <div class="col-md-3" style="margin-left: 80px">
                    <input v-model="patentValue" ref="patentInput" type="text" class="form-control" placeholder="Please enter the patent publication">
                </div>
                <div class="col-md-2">
                    <button class="btn btn-light" type="button" @click="searchPatentIfExists">Search patent</button>
                </div>
                <div class="col-md-4">
                    <div v-if="isSearching"><span class="spinner-border text-primary me-1" role="status"></span> Looking for application</div>
                    <div v-else>
                        <span v-if="nb_response === 0">There is no corresponding patent.</span>
                        <span v-else-if="nb_response > 1">
                            There is {{nb_response}} publications that match your query.
                            <div v-for="(patent_elem, index) in this.patent_to_add" :key="patent_elem.APID">
                                <show-patent :patent="patent_elem" :index=0 :showDelete=false :viewMode="'Edit'"></show-patent>
                                <button class="btn btn-light" type="button" @click="addNewPatent(patent_elem, index)">Add patent to '{{ this.parentValue }}'</button>
                            </div>
                        </span>
                        <span v-else-if="nb_response === 1">
                            <show-patent :patent="patent_to_add" :index=0 :showDelete=false :viewMode="'Edit'"></show-patent>
                            <button class="btn btn-light" type="button" @click="addNewPatent(this.patent_to_add, 0)">Add patent to '{{ this.parentValue }}'</button>
                        </span>
                    </div>
                </div>
            </template>

        </div>
    </div>
</template>

<script>
    import axios from "axios";

    var ONE_HOUR = 60 * 60 * 1000; /* milliseconds to hours */

    const email="sylvain.goiran@gmail.com"
    // const password="Sgoiran123!"
    const md5_password="880bc5aba354b2d420792f709dd4a205"
    const md5_hash="05c96b432d5ab5af9b4230897f7ab541"
    const host="https://www.orbit.com/rest/iorbit"

    import ShowPatent from './ShowPatent.vue';

    export default { 
        name: 'new-patent',
        props: ['parentValue', 'resetPatent'],
        components: { ShowPatent },
        data() {
            return {
                patentValue: "",
                showInput: false,
                patentResponse: '',
                isSearching: false
            }
        },
        computed: {
            nb_response() {
                if (this.patentResponse && "documents" in this.patentResponse && Array.isArray(this.patentResponse["documents"])) {
                    return (this.patentResponse["documents"].length)
                }
                else if (this.patentResponse && "nb" in this.patentResponse && this.patentResponse.nb === 0) {
                    return 0
                }
                else {
                    return -1
                }
            },
            patent_to_add() {
                if(this.nb_response==1){
                    return this.patentResponse["documents"][0]
                } else if (this.nb_response > 1){
                    return this.patentResponse["documents"]
                } else {
                    return null
                }
            }

        },
        methods: {
            addNewPatent(value, index) {
                this.$emit("addNewPatent", value);
                this.patentResponse["documents"].splice(index, 1)
                this.patentValue = ""
                if(this.patentResponse["documents"].length === 0){
                    this.showInput = false
                }
            },
            async setLocalStorageAuth() {
                const login_url = host + "/user/session/"
                const headers = {
                    'Content-Type': 'application/json'
                }
                try {
                    const response = await axios.post(login_url, {
                        "logon":email,
                        "password": md5_password,
                        "b2blogon":"iorbitv1",
                        "hash":md5_hash,
                    }, { headers })

                    const data = response.data
                    if (data.status == "ok") {
                        localStorage.ticket = data.ticket
                        localStorage.authDate = new Date()
                        localStorage.shard = data.shard
                    }
                } catch (error) {
                    console.log("Error: ", error)
                }
            },
            async searchPatentIfExists() {
                if (this.patentValue){
                    this.isSearching = true
                    if(!localStorage.shard || !localStorage.ticket || !localStorage.authDate ||
                    ((new Date()) - Date.parse(localStorage.authDate) > ONE_HOUR)){ //
                        await this.setLocalStorageAuth()
                    }
                    let ticket = localStorage.ticket
                    let url = `https://${localStorage.shard}/rest/iorbit/user/search/FULLPAT`;

                    if(this.patentValue.indexOf(";") > 0) {
                        this.patentValue = this.patentValue.replaceAll(";", " OR ")
                    }
                    this.patentValue = this.patentValue.replaceAll("/", "").replaceAll(",", "").replaceAll(".", "")
                    //remove kind code.
                    const lastlastcharacter = this.patentValue.charAt(this.patentValue.length - 2)
                    const lastcharacter = this.patentValue.charAt(this.patentValue.length - 1)
                    const re_letter = /[a-zA-Z]/;
                    const re_num = /[0-9]/;

                    if(re_letter.test(lastlastcharacter) && re_num.test(lastcharacter)){
                        this.patentValue = this.patentValue.slice(0, (this.patentValue.length-2))
                    } else if (re_letter.test(lastcharacter) && re_num.test(lastlastcharacter)){
                        this.patentValue = this.patentValue.slice(0, (this.patentValue.length-1))
                    }

                    let query = `(${this.patentValue})%2FAP%2FPN%2FXPN`;
                    let fields = "ETI PA NPA APD IN V_APL PN IMG ACT XAP" 
                    let full_url = `${url};query=${query};anonymous=true;ticket=${ticket};range=1-10;fields=${fields};sorting=RELEVANCE`;
                    let response = await axios.get(full_url)

                    this.patentResponse = response.data
                    this.isSearching = false
                }
            },
            toggleInput() {
                this.showInput = !this.showInput
                if (this.showInput === true){
                    this.$nextTick(function () {
                        if (this.$refs["patentInput"]) {this.$refs["patentInput"].focus();}
                    })
                    this.$emit("resetToggle", "patent");
                }
            }
        },
        watch: {
            resetPatent(){
                if (this.resetPatent === true) {
                    this.showInput = false;
                    this.patentValue = ""
                }
            }
        }

    }
</script>

<style scoped>

button {
    display: inline-block;
    white-space: nowrap;
}

</style>