<template>
  <div>
    <div class="row m-3">
        <div class="col-9" >
            <div class="row">
                <h3>Competition tree</h3>
                <div class="left-column">
                    <svg></svg>
                </div>

            </div>
            <div class="row m-3">
                <span class="h3">
                    SWOT analysis
                    <button class="btn btn-light" @click="editSwot = true">
                        Edit <i class="ri-edit-fill"></i>
                    </button>
                </span>
                <span>
                </span>

                <div class="col-6 swot">
                    <b>Strength</b>
                    <div v-if="editSwot">
                        <textarea class="form-control" v-model="myStrength"></textarea>
                    </div>
                    <div v-else>
                        {{ myStrength }}
                    </div>
                </div>
                <div class="col-6 swot">
                    <b>Weakness</b>
                    <div v-if="editSwot">
                        <textarea class="form-control" v-model="myWeakness"></textarea>
                    </div>
                    <div v-else>
                        {{ myWeakness }}
                    </div>
                </div>
                <div class="col-6 swot">
                    <b>Opportunities</b>
                    <div v-if="editSwot">
                        <textarea class="form-control" v-model="myOpportunity"></textarea>
                    </div>
                    <div v-else>
                        {{ myOpportunity }}
                    </div>
                </div>
                <div class="col-6 swot">
                    <b>Threats</b>
                    <div v-if="editSwot">
                        <textarea class="form-control" v-model="myThreat"></textarea>
                    </div>
                    <div v-else>
                        {{ myThreat }}
                    </div>
                </div>
                <div class="my-3">
                    <button v-if="editSwot" class="btn btn-light" @click="validateSwot">
                        Validate <i class="ri-check-fill"></i>
                    </button>
                </div>
            </div>
        </div>
        <div v-if="showPatent" class="col-3">
            <h3>Card data</h3>
            <show-patent 
                :patent="clicked_patent"
                :viewMode="'Play'"
                :index="clicked_index"
                @newComment="updateComment"
                @newCriticity="updateCriticity"
                @newIncludeInTimeline="updateIncludeInTimeline"
            >
            </show-patent>
        </div>
        <div v-else class="col-3">
            <h3>Card data</h3>
        </div>
    </div>
    <div class="tooltip"></div>

  </div>
</template>
  
<script>
    import * as d3 from 'd3'
    import ShowPatent from './ShowPatent.vue'
    import { getNumberOfPatentForNode, transform_node_to_data, changePatentPropertyInNode } from '@/functions/treeutils.js';
    
    function returnCircleFillColor(d) {
        if(d._children && d.children === null) {
            return "lightsteelblue" 
        } else if (d._children && d.children) {
            return "white"
        } else if (d.data.applicant){
            if(d.data.patent.patentCriticity && d.data.patent.patentCriticity === "1") {
                return "green"
            } else if(d.data.patent.patentCriticity && d.data.patent.patentCriticity === "2") {
                return "orange"
            } else if(d.data.patent.patentCriticity && d.data.patent.patentCriticity === "3") {
                return "red"
            } else  {
                return "white"
            }
        } else if (d._children === null) {
            return "white"
        }
    }

    export default { 
        props: [ 'tree'],
        data() {
            return {
                showPatent: false,
                clicked_patent: {},
                clicked_index: 0,
                editSwot: false,
                myStrength: this.tree.strength || '',
                myWeakness: this.tree.weakness || '',
                myOpportunity: this.tree.opportunity || '',
                myThreat: this.tree.threat || ''
            }
        },
        name: 'tree-chart',
        components: { ShowPatent },
        emits: ['newSwot', 'update-tree'],
        mounted() {
            this.reloadTree()
        },
        watch: {
            tree() {
                this.reloadTree()
            }
        },
        methods: {
            reloadTree() {
                // remove old tree to reload the new one
                var svg = d3.select("svg");
                svg.selectAll("*").remove();

                let data = transform_node_to_data({"label": (this.tree.label || ""), "nodes": (this.tree.nodes || []), "patents": (this.tree.patents || [])})
                this.viewTree(data)
                this.myStrength = this.tree.strength;
                this.myWeakness = this.tree.weakness;
                this.myOpportunity = this.tree.opportunity;
                this.myThreat = this.tree.threat;
            },
            viewTree(data) {
                const width = 1200;
                const marginTop = 50;
                const marginRight = 40;
                const marginBottom = 50;
                const marginLeft = 120;

                // Rows are separated by dx pixels, columns by dy pixels. These names can be counter-intuitive
                // (dx is a height, and dy a width). This because the tree must be viewed with the root at the
                // “bottom”, in the data domain. The width of a column is based on the tree’s height.
                const root = d3.hierarchy(data);
                const dx = 35;
                const dy = (width - marginRight - marginLeft) / (1 + root.height);

                // Define the tree layout and the shape for links.
                const tree = d3.tree().nodeSize([dx, dy]);
                const diagonal = d3.linkHorizontal().x(d => d.y).y(d => d.x);

                // Create the SVG container, a layer for the links and a layer for the nodes.
                const svg = d3.select("svg")
                    .attr("width", width)
                    .attr("height", dx)
                    .attr("viewBox", [-marginLeft, -marginTop, width, dx])
                    .attr("style", "max-width: 100%; height: auto; background-color: white; font: 15px sans-serif; user-select: none;");

                // var tooltip = svg.append("g")
                //     .attr("class", "tooltip-area")
                //     .append("text")
                //     .attr("class", "tooltip-area__text")
                //     .style('opacity', 0)
                var tooltip = d3.select(".tooltip")
                    .style("opacity", 0);

                const gLink = svg.append("g")
                    .attr("fill", "none")
                    .attr("stroke", "#555")
                    .attr("stroke-opacity", 0.4)
                    .attr("stroke-width", 1.5);

                const gNode = svg.append("g")
                    .attr("cursor", "pointer")
                    .attr("pointer-events", "all");

                /* eslint-disable-next-line */
                const mouseover = (event, d) => {
                    tooltip.style("opacity", 1);
                };

                /* eslint-disable-next-line */
                const mouseleave = (event, d) => {
                    tooltip.style('opacity', 0);
                }

                const mousemove = (event, d) => {
                    tooltip.style("background-color", "white")
                        .style("border", "1px solid black")
                        .style("padding", "2px")
                        .style("position", "absolute")
                        .style("top", event.pageY + 10 + "px")
                        .style("left", event.pageX + 10 + "px")
                        .style("opacity", 1)
                        .style("max-width", "600px")
                        // .attr('transform', `translate(${event.pageY - 80}, ${event.pageX - 20})`);

                    // const text = d3.select('.tooltip-area__text');
                    if ("applicant" in d.data) {
                        if(d.data.comment != ""){
                            tooltip.html(`Comment: ${d.data.comment}`);
                        }
                        else {
                            tooltip.html("No comment has been written")
                        }
                    }
                    else {
                        tooltip.html(`This node has a total of ${getNumberOfPatentForNode(d)} patents`)
                    }

                    // tooltip
                    //     .attr('transform', `translate(${d.y0 - 80}, ${d.x0 - 20})`);
                };

                const mouseclick = (event, d) => {
                    if ("applicant" in d.data) {
                        this.clicked_patent=d.data.patent || {}
                        this.clicked_index=d.data.index
                        this.showPatent=true;
                    }
                };

                function update(event, source) {
                    const duration = event?.altKey ? 2500 : 250; // hold the alt key to slow down the transition
                    const nodes = root.descendants().reverse();
                    const links = root.links();

                    // Compute the new tree layout.
                    tree(root);

                    let left = root;
                    let right = root;
                    root.eachBefore(node => {
                        if (node.x < left.x) left = node;
                        if (node.x > right.x) right = node;
                    });

                    const height = right.x - left.x + marginTop + marginBottom;

                    const transition = svg.transition()
                        .duration(duration)
                        .attr("height", height)
                        .attr("viewBox", [-marginLeft, left.x - marginTop, width, height])
                        .tween("resize", window.ResizeObserver ? null : () => () => svg.dispatch("toggle"));

                    // Update the nodes…
                    const node = gNode.selectAll("g")
                        .data(nodes, d => d.id);
    
                    // Enter any new nodes at the parent's previous position.
                    const nodeEnter = node.enter().append("g")
                        /* eslint-disable-next-line */
                        .attr("transform", d => `translate(${source.y0},${source.x0})`)
                        .attr("fill-opacity", 0)
                        .attr("stroke-opacity", 0)
                        .on("click", (event, d) => {
                            d.children = d.children ? null : d._children;
                            update(event, d);
                        });

                    nodeEnter.append("circle")
                        .attr("r", 5)
                        .style("fill", d => returnCircleFillColor(d))
                        .attr("stroke-width", 1)
                        .attr("stroke", "black")

                    nodeEnter.append("text")
                        .attr("dy", "0.35em")
                        .attr("x", d => d._children ? -10 : 10)
                        .attr("text-anchor", d => d._children ? "end" : "start")
                        .text(d => d.data.name)
                        .on("mousemove", mousemove)
                        .on("mouseleave", mouseleave)
                        .on("mouseover", mouseover)
                        .on("click", mouseclick)
                    .clone(true).lower()
                        .attr("stroke-linejoin", "round")
                        .attr("stroke-width", 3)
                        .attr("stroke", "white")


                    // Transition nodes to their new position.
                    const nodeUpdate = node.merge(nodeEnter).transition(transition)
                        .attr("transform", d => `translate(${d.y},${d.x})`)
                        .attr("fill-opacity", 1)
                        .attr("stroke-opacity", 1);

                    nodeUpdate.select("circle")
                        .attr("r", 4.5)
                        .style("fill", d => returnCircleFillColor(d))

                    // Transition exiting nodes to the parent's new position.
                    /* eslint-disable-next-line */
                    const nodeExit = node.exit().transition(transition).remove()
                        /* eslint-disable-next-line */
                        .attr("transform", d => `translate(${source.y},${source.x})`)
                        .attr("fill-opacity", 0)
                        .attr("stroke-opacity", 0);

                    // Update the links…
                    const link = gLink.selectAll("path")
                        .data(links, d => d.target.id);

                    // Enter any new links at the parent's previous position.
                    const linkEnter = link.enter().append("path")
                        /* eslint-disable-next-line */
                        .attr("d", d => {
                            const o = {x: source.x0, y: source.y0};
                            return diagonal({source: o, target: o});
                        });

                    // Transition links to their new position.
                    link.merge(linkEnter).transition(transition)
                        .attr("d", diagonal);

                    // Transition exiting nodes to the parent's new position.
                    link.exit().transition(transition).remove()
                        /* eslint-disable-next-line */
                        .attr("d", d => {
                            const o = {x: source.x, y: source.y};
                            return diagonal({source: o, target: o});
                        });

                    // Stash the old positions for transition.
                    root.eachBefore(d => {
                        d.x0 = d.x;
                        d.y0 = d.y;
                    });
                }

                // Do the first update to the initial configuration of the tree — where a number of nodes
                // are open (arbitrarily selected as the root, plus nodes with 7 letters).
                root.x0 = dy / 2;
                root.y0 = 0;
                root.descendants().forEach((d, i) => {
                    d.id = i;
                    d._children = d.children;
                    if (d.depth && d.data.name.length !== 7) d.children = null;
                });

                update(null, root);

                return svg.node();

            },
            validateSwot() {
                this.$emit("newSwot", this.myStrength, this.myWeakness, this.myOpportunity, this.myThreat)
                this.editSwot = false
            },
            updateComment(index, newComment){
                let whereAmI = this.clicked_patent.whereAmI
                let newTree = changePatentPropertyInNode(this.tree, whereAmI, index, 'patentComment', newComment)
                this.$emit('update-tree', newTree)
            },
            updateCriticity(index, newCriticity){
                let whereAmI = this.clicked_patent.whereAmI
                let newTree = changePatentPropertyInNode(this.tree, whereAmI, index, 'patentCriticity', newCriticity)
                this.$emit('update-tree', newTree)
                this.reloadTree()
            },
            updateIncludeInTimeline(index, newIncludeInTimeline){
                let whereAmI = this.clicked_patent.whereAmI
                let newTree = changePatentPropertyInNode(this.tree, whereAmI, index, 'includeInTimeline', newIncludeInTimeline)
                this.$emit('update-tree', newTree)
            }
        }
    }
</script>
  
<style scoped>

h2 {
    margin-top: 30px;
    margin-bottom : 30px
}

svg {
    padding: 10px;
    border: solid lightblue;
    margin-bottom: 30px;
}

.node circle {
  fill: #fff;
  stroke: steelblue;
  stroke-width: 3px;
}

.node text {
  font: 12px sans-serif;
}

.link {
  fill: none;
  stroke: #ccc;
  stroke-width: 2px;
}

.bar {
    width: 100px;
    height: 30px;
    border: 1px solid black;
}

.swot {
    border: 1px solid grey
}

.left-column {
    border: 1px solid lightgrey;
    max-height: 80vh;          /* not available in Bootstrap */
    overflow-y: scroll;
}

</style>