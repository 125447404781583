<template>
    <div class="register">
      <h2>Register</h2>
      <form @submit.prevent="submitRegistrationForm" class="register-form">
        <div class="form-group">
          <label for="email">Email:</label>
          <input type="email" id="email" v-model="email" required>
        </div>
        <div class="form-group">
          <label for="password">Password:</label>
          <input type="password" id="password" v-model="password" required>
        </div>
        <button type="submit">Register</button>
      </form>
      <p>Already have an account? <router-link to="/login">Login</router-link></p>
    </div>
  </template>
  
<script>
// import { authService } from '@/services/apiService'; // Adjust import path based on your project structure
import { mapActions } from 'vuex';

export default {
    data() {
        return {
            email: '',
            password: ''
        };
    },
    methods: {
        ...mapActions(['register']),
        async submitRegistrationForm() {
            try {
                await this.register({ email: this.email, password: this.password });
                this.$router.push('/confirm-registration');
            } catch (error) {
                console.error('Registration failed:', error);
            }
        },

        // async register() {
        //     try {
        //         const response = await authService.signup({
        //             email: this.email,
        //             password: this.password
        //         });
        //         // Check if registration was successful
        //         if (response.data.success) {
        //         // Redirect user to login page
        //             this.$router.push('/confirm-registration');
        //         } else {
        //             // Display error message to user
        //             console.error(response.data.message);
        //         }
        //     } catch (error) {
        //         console.error('Error registering:', error);
        //     }
        // }

    }
};
  </script>
  
  <style scoped>
  .register {
    display: flex;
    flex-direction: column;
    align-items: center; /* Vertically align items */
    max-width: 400px;
    margin: 0 auto;
    margin-top: 200px; /* Adjust margin as needed */
  }
  .form-group {
    display: flex;
    flex-direction: column;
    width: 300px; 
  }
  .register form {
    display: flex;
    flex-direction: column;
  }
  .register form div {
    margin-bottom: 10px;
  }
  .register form label {
    font-weight: bold;
  }
  .register form input {
    padding: 5px;
    font-size: 1rem;
  }
  .register form button {
    padding: 8px 15px;
    background-color: #007bff;
    color: #fff;
    border: none;
    cursor: pointer;
  }
  .register form button:hover {
    background-color: #0056b3;
  }
  p {
    margin-top: 15px;
  }
  </style>
  