

export function getNumberOfPatentForNode(node) {
    if('data' in node){
        node = node.data
    }

    if (node && node.applicant) {
        return 1
    }
    else {
        let sum = 0
        if (node && node.children && node.children.length){
            for (let i=0; i < node.children.length; i ++){
                sum += getNumberOfPatentForNode(node.children[i])
            }
        }
        return sum            
    }
}

function getNumberOfPatentForSankeyNode(node) {
    let number = 0
    if (node.patents) {
        number += node.patents.length
    }

    if (node.nodes){
        for (let i=0; i < node.nodes.length; i ++){
            number += getNumberOfPatentForSankeyNode(node.nodes[i])
        }
    }
    return number
}

export function transform_node_to_data(node) {
    let nodes = []
    if(node.nodes){
        nodes = node.nodes.map((node) => {
            return transform_node_to_data(node)
        })
    }
    let patents = []
    if (node.patents) {
        patents = node.patents.map((elem, index) => {
            const return_obj = get_data_from_patent(elem);
            const label = return_obj["label"]
            const title = return_obj["title"]
            const applicant = return_obj["applicant"]
            const comment = ("patentComment" in elem) ? elem["patentComment"] : ""
            const patent = elem
            return {"name": label, "applicant": applicant, "title": title, "comment": comment, "index": index, "patent": patent}
        })
    }
    let name = node.label 
    // name += patents.length > 0 ? (": " + patents.length + " patents") : ""

    return {"name": name , "children": [...patents, ...nodes]}
}

export function get_data_from_patent(elem){
    
    let label = ''
    let title = ''
    let applicant = ''
    let date = ''
    let year = ''
    if("ID" in elem) {
        label = elem["ID"]
        if(elem["ID"].indexOf("_") > 0){
            label = elem["ID"].substr(0, elem["ID"].indexOf("_"))
        }
        title = elem["ETI"]
        applicant = elem["NPA"]
        date = elem["APD"]
        year = 1*elem["APD"].substring(0,4)
    // if elem is an NPL
    } else if ("title" in elem) {
        label = elem.title
        title = elem.title
        applicant = elem.player
        date = elem.inputDate
        year = 1*elem.inputDate.substring(0,4)
    }

    return {"label": label, "title": title, "applicant": applicant, "date": date, "year": year}
}

function node_id(node_name){
    if(node_name){
        return node_name.replace(/[^A-Z0-9]/ig, "")
    }
    else {
        return "NoName"
    }
}

export function is_a_patent(patent) {
    if ("ETI" in patent) {
        return true
    } else {
        return false
    }
}

export function is_an_npl(patent) {
    if ("title" in patent) {
        return true
    } else {
        return false
    }
}


export function getPatentListingFromNode(node){
    let list_patents = []
    if (node.patents) {
        let modified_patent_listing = node.patents.map((element, index) => {
            element['patent_index'] = index
            return element
        })
        list_patents = modified_patent_listing
    }

    if (node.nodes){
        for (let i=0; i < node.nodes.length; i ++){
            list_patents = [...list_patents, ...getPatentListingFromNode(node.nodes[i])]
        }
    }
    return list_patents
}

export function getTotalApplicants(node) {
    let total_applicants = []
    if (node.patents) {
        node.patents.forEach((patent) => {
            let applicants = getApplicantsFromPatent(patent)
            for(let i=0; i < applicants.length; i++) {
                let applicant = applicants[i]
                // We count one patent for each applicant even if he there is shared co-ownership
                let value = 1 
                if(total_applicants.some(e => e.applicant.toUpperCase() === applicant.toUpperCase())){
                    const index = total_applicants.findIndex(e => e.applicant.toUpperCase() === applicant.toUpperCase())
                    total_applicants[index].value += value;                    
                } else {
                    total_applicants.push({applicant: applicant, value: value})
                }
            }
        });
    }

    if (node.nodes){
        for (let i=0; i < node.nodes.length; i ++){
            let child_total_applicants = getTotalApplicants(node.nodes[i])
            child_total_applicants.forEach((applicant_obj) => {
                if(total_applicants.some(e => e.applicant.toUpperCase() === applicant_obj.applicant.toUpperCase())){
                    const index = total_applicants.findIndex(e => e.applicant.toUpperCase() === applicant_obj.applicant.toUpperCase())
                    total_applicants[index].value += applicant_obj.value;                    
                } else {
                    total_applicants.push({applicant: applicant_obj.applicant, value: applicant_obj.value})
                }
            });
        }
    }
    return total_applicants
}

export function getApplicantsFromPatent(patent) {
    let applicants = []
    let applicant_str = "No name"
    if("NPA" in patent) {
        if (patent["NPA"]) {
            applicant_str = patent["NPA"]
        }
    } else if ("player" in patent) {
        if (patent.player){
            applicant_str = patent.player
        }
    }

    if(applicant_str.includes("<br/>")){
        applicants = applicant_str.split("<br/>")
    } else if (applicant_str.includes("/")) {
        applicants = applicant_str.split("/").map((e) => e.trim())
    } else {
        applicants = [applicant_str]
    }

    return applicants
}

function build_initial_sankey_node(node){
    let sankey_node = {name: node.label, id:node_id(node.label), value: 0, patent_number: 0}
    for (let npl_type of ["merge_and_acquisition", "scientific_article", "investment", "partnership_and_collaboration", "hiring","other"]){
        sankey_node[npl_type] = 0
    }

    if(node.patents) {
        node.patents.forEach((patent) => {
            let is_patent_a_patent = is_a_patent(patent) 
            let is_patent_an_npl = is_an_npl(patent)

            sankey_node.value += 1;
            if(is_patent_a_patent){
                sankey_node.patent_number += 1;
            } else if (is_patent_an_npl){
                if("type" in patent){
                    sankey_node[patent.type] += 1
                }
                else {
                    sankey_node["other"] += 1;
                }
            }
        });
    }
    if (node.nodes){
        node.nodes.forEach((child_node) => {
            let transformed_child_node = build_initial_sankey_node(child_node)
            sankey_node.value += transformed_child_node.value
            sankey_node.patent_number += transformed_child_node.patent_number
            for (let npl_type of ["merge_and_acquisition", "scientific_article", "investment", "partnership_and_collaboration", "hiring","other"]){
                sankey_node[npl_type] += transformed_child_node[npl_type]
            }
        });
    }
    return sankey_node
}

export function transform_node_to_sankey_data(node, selected_applicants) {
    let sankey_node = build_initial_sankey_node(node)
    let sankey_nodes = [sankey_node] //getNumberOfPatentForSankeyNode(node)

    let sankey_links = []

    if(node.nodes){
        node.nodes.forEach((child_node) => {
            let transformed_child = transform_node_to_sankey_data(child_node, selected_applicants)

            sankey_links.push({source:node.label,target:child_node.label,value:getNumberOfPatentForSankeyNode(child_node)});

            for(let i=0; i < transformed_child.links.length ; i++) {
                let transformed_child_link = transformed_child.links[i]
                if(sankey_links.some(e => e.source === transformed_child_link.source && e.target === transformed_child_link.target)){
                    const index = sankey_links.findIndex(e => e.source === transformed_child_link.source && e.target === transformed_child_link.target)
                    sankey_links[index].value += transformed_child_link.value;                    
                } else {
                    sankey_links.push({source: transformed_child_link.source, target: transformed_child_link.target, value: transformed_child_link.value})
                }
            }

            for(let i=0; i<transformed_child.nodes.length; i++){
                let transformed_child_node = transformed_child.nodes[i]
                if(sankey_nodes.some(e => e.name === transformed_child_node.name)){
                    const index = sankey_nodes.findIndex(e => e.name === transformed_child_node.name)
                    sankey_nodes[index].value += transformed_child_node.value;
                    sankey_nodes[index].patent_number += transformed_child_node.patent_number
                    for (let npl_type of ["merge_and_acquisition", "scientific_article", "investment", "partnership_and_collaboration", "hiring","other"]){
                        if (npl_type in transformed_child_node) {
                            if(!(npl_type in sankey_nodes[index])){
                                sankey_nodes[index][npl_type] = 0
                            }
                            sankey_nodes[index][npl_type] += transformed_child_node[npl_type]
                        }
                    }

                } else {
                    // let sankey_node = {name: transformed_child_node.name, id:transformed_child_node.id, value: transformed_child_node.value}
                    // for (let npl_type of ["merge_and_acquisition", "scientific_article", "investment", "partnership_and_collaboration", "hiring","other"]){
                    //     if (npl_type in transformed_child_node) {
                    //         sankey_node[npl_type] = transformed_child_node[npl_type]
                    //     }
                    // }

                    sankey_nodes.push(transformed_child_node)
                }
            }
            // sankey_nodes = [...sankey_nodes, ...transformed_child_nodes.nodes]
        })
    }

    if(node.patents) {
        node.patents.forEach((patent) => {
            
            let applicants = getApplicantsFromPatent(patent)

            let is_patent_a_patent = is_a_patent(patent) 
            let is_patent_an_npl = is_an_npl(patent)
            let npl_type = "Other"
            if ("type" in patent) {
                npl_type = patent.type
            }

            if(!is_patent_an_npl) {
                npl_type = null
            }

            for(let i=0; i<applicants.length; i++) {
                let applicant = ""
                let value = 1/applicants.length

                if (selected_applicants.includes(applicants[i])){
                    applicant = applicants[i]
                } else {
                    applicant = "Other"
                }

                if(sankey_nodes.some(e => e.name === applicant)){
                    const index = sankey_nodes.findIndex(e => e.name === applicant)
                    sankey_nodes[index].value += value;
                    if (is_patent_a_patent) {
                        sankey_nodes[index].patent_number += 1
                    } else if (npl_type && is_patent_an_npl) {
                        if(!(npl_type in sankey_nodes[index])){
                            sankey_nodes[index][npl_type] = 0;
                        }
                        sankey_nodes[index][npl_type] += 1;
                    }

                } else {
                    let sankey_node = {name: applicant, id:node_id(applicant), value: value, patent_number: is_patent_a_patent ? 1 : 0}
                    if (npl_type && is_patent_an_npl){
                        sankey_node[npl_type] = 1
                    }
                    sankey_nodes.push(sankey_node)
                }

                if(sankey_links.some(e => e.source === node.label && e.target === applicant)){
                    const index = sankey_links.findIndex(e => e.source === node.label && e.target === applicant)
                    sankey_links[index].value += value;                    
                } else {
                    sankey_links.push({source: node.label, target: applicant, value: value})
                }

            }

        })
    }

    return {nodes: sankey_nodes , links: sankey_links}
}

//  [{source: "Microsoft", target: "Amazon", type: "licensing"}, {source: "Microsoft", target: "HTC", type: "licensing"}, {source: "Samsung", target: "Apple", type: "suit"}, {source: "Motorola", target: "Apple", type: "suit"}, {source: "Nokia", target: "Apple", type: "resolved"}, {source: "HTC", target: "Apple", type: "suit"}, {source: "Kodak", target: "Apple", type: "suit"}, {source: "Microsoft", target: "Barnes & Noble", type: "suit"}, {source: "Microsoft", target: "Foxconn", type: "suit"}, {source: "Oracle", target: "Google", type: "suit"}, {source: "Apple", target: "HTC", type: "suit"}, {source: "Microsoft", target: "Inventec", type: "suit"}, {source: "Samsung", target: "Kodak", type: "resolved"}, {source: "LG", target: "Kodak", type: "resolved"}, {source: "RIM", target: "Kodak", type: "suit"}, {source: "Sony", target: "LG", type: "suit"}, {source: "Kodak", target: "LG", type: "resolved"}, {source: "Apple", target: "Nokia", type: "resolved"}, {source: "Qualcomm", target: "Nokia", type: "resolved"}, {source: "Apple", target: "Motorola", type: "suit"}, {source: "Microsoft", target: "Motorola", type: "suit"}, {source: "Motorola", target: "Microsoft", type: "suit"}, {source: "Huawei", target: "ZTE", type: "suit"}, {source: "Ericsson", target: "ZTE", type: "suit"}, {source: "Kodak", target: "Samsung", type: "resolved"}, {source: "Apple", target: "Samsung", type: "suit"}, {source: "Kodak", target: "RIM", type: "suit"}, {source: "Nokia", target: "Qualcomm", type: "suit"}],
export function transform_node_to_force_data(node, selected_applicants) {
    let force_links = []

    if(node.patents) {
        node.patents.forEach((patent) => {
            
            let applicants = getApplicantsFromPatent(patent)

            let is_patent_a_patent = is_a_patent(patent) 
            let is_patent_an_npl = is_an_npl(patent)
            let type = "Other"
            if (is_patent_an_npl && "type" in patent) {
                type = patent.type
            }
            if(is_patent_a_patent) {
                type = "coownership"
            }

            if (applicants.length >= 2){
                for (let i = 0; i < (applicants.length - 1); i++) {
                    for (let j = i + 1; j < applicants.length; j++) {
                        let force_link = {source: applicants[i], target: applicants[j], type: type}
                        force_links.push(force_link)
                    }
                }
            }
        })
    }


    if(node.nodes){
        node.nodes.forEach((child_node) => {
            let child_force_links = transform_node_to_force_data(child_node, selected_applicants)
            force_links = [...force_links, ...child_force_links]
        })
    }

    return force_links
}



export function findNodeInTree(node, node_name){
    if (node.label === node_name) {
        return node
    }

    if (node.nodes){
        for (let i=0; i < node.nodes.length; i ++){
            let found_node = findNodeInTree(node.nodes[i], node_name)
            if (found_node){
                return found_node
            }
        }
    }
    return null
}

export function changePatentPropertyInNode(node, whereAmI, index, key, value){
    let whereAmI_arr = whereAmI.split(" | ")
    if (node.label === whereAmI_arr[0]) {
        whereAmI_arr.shift();
    }

    if(whereAmI_arr.length === 0){
        node.patents[index][key] = value
        return node
    }
    else {
        for (let i=0; i< node.nodes.length; i++){
            if (node.nodes[i].label === whereAmI_arr[0]){
                node.nodes[i] = changePatentPropertyInNode(node.nodes[i], whereAmI_arr.join(" | "), index, key, value)
            }
        }
        return node
    }
}
