<template>
    <div>
        <form @submit.prevent>
        <div class="form-group row">
            <div class="col-md-1" @click="toggleDelete">
                <button 
                    class="btn btn-sm btn-light rounded-pill"
                    v-tooltip
                    :title="'Delete current node (' + this.parentValue + ')'"
                >
                    <span><i class="ri-folder-line mx-2"></i></span>
                    <span class="badge rounded-pill bg-warning" style="margin-left: -15px; margin-right: 10px; font-size: 5px">-</span>
                    <span>Delete node </span>
                </button>
            </div>
            <div v-if="showDelete" class="col-md-4" style="margin-left: 80px">
                <button class="btn btn-light" type="button" @click="deleteNode">Delete '{{ this.parentValue }}'? This will also delete all patents attached to this node</button>
            </div>
        </div>
        </form>
    </div>
</template>

<script>
    export default { 
        name: 'delete-node',
        props: ['parentValue', 'resetDeleteNode'],
        data() {
            return {
                showDelete: false
            }
        },
        methods: {
            deleteNode() {
                this.$emit("deleteNode", true);
                this.showDelete = false
            },
            toggleDelete() {
                this.showDelete = !this.showDelete
                if (this.showDelete) {
                    this.$emit("resetToggle", "deleteNode");
                }
            }
        },
        watch: {
            resetDeleteNode(){
                if (this.resetDeleteNode === true) {
                    this.showDelete = false;
                }
            }
        }
    }
</script>

<style scoped>

button {
    display: inline-block;
    white-space: nowrap;
}

</style>